export const PATHS = {
  HOME: '/',
  ABOUT: '/about',
  CAREERS: '/about/careers',
  TEAM: '/about/team',
  EYTAN: '/about/Eytan-Bensoussan',
  JUSTIN: '/about/Justin-Adler',
  BLAKE: '/about/Blake-Edwards',
  OUR_CUSTOMERS: '/our-customers',
  SMALL_BUSINESS: '/our-customers/small-business',
  FREELANCER: '/our-customers/freelancer',
  STARTUP: '/our-customers/startup',
  PRODUCT: '/product',
  FEATURES: '/product/features',
  PRICING: '/product/pricing',
  INTEGRATIONS: '/product/integrations',
  AMAZON: '/product/integrations/amazon',
  EXPENSIFY: '/product/integrations/expensify',
  FRESHBOOKS: '/product/integrations/freshbooks',
  PAYPAL: '/product/integrations/paypal',
  QUICKBOOKS: '/product/integrations/quickbooks',
  SHOPIFY: '/product/integrations/shopify',
  STRIPE: '/product/integrations/stripe',
  WAVE: '/product/integrations/wave',
  // XERO: '/product/integrations/xero',
  GETTING_STARTED: '/product/getting-started',
  CONTACT_US: '/contact-us',
  FAQ: '/faq',
  OPEN_BANKING: '/open-banking',
  PRIVACY_POLICY: 'privacy-policy',
  PRIVACY_POLICY_BC: 'privacy-policy-bc',
  ACCOUNT_AGREEMENT: 'account-agreement',
  ELECTRONIC_AGREEMENT: 'esign',
  SECURITY: '/security',
  PARTNERSHIPS: '/partners',
}
