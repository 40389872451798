import React from 'react'
import { Box, Image } from 'rebass'
import { theme } from '../../shared'
import { FlexRowLayout, Text } from '@northone/ui'

interface ITextBulletProps {
  imageSrc: string
  isSelected?: boolean
  imageSelectedSrc?: string
  imageDesc?: string
  text: string
  setSelected: () => void
}

export const TextBullet = ({
  imageSrc,
  imageSelectedSrc,
  isSelected = false,
  imageDesc,
  text,
  setSelected,
}: ITextBulletProps): JSX.Element => {
  const BODY_X_LARGE_TEXT_STROKE = `0.1px ${theme.colors.ui1}`

  return (
    <FlexRowLayout sx={{ marginBottom: 0, cursor: 'pointer', alignItems: 'center' }} onClick={setSelected}>
      <Box
        sx={{
          marginTop: '3px',
          width: [35, 35, 22, 25, 35],
          height: [35, 35, 22, 25, 35],
          alignSelf: 'center',
        }}
      >
        <Image height={'auto'} width={'auto'} src={isSelected ? imageSelectedSrc : imageSrc} alt={imageDesc} />
      </Box>
      <Text
        tag={['body', 'body', 'body-small', 'body-small', 'body-large']}
        textColor={theme.colors.blackPrimary}
        sx={{
          marginLeft: '7px',
          width: '100%',
          WebkitTextStroke: isSelected ? BODY_X_LARGE_TEXT_STROKE : undefined,
          fontFamily: isSelected ? theme.fonts.grtskPetaSemiBold : theme.fonts.grtskPetaLight,
          fontSize: '16px',
          lineHeight: '22px',
          color: theme.colors.blackPrimary,
        }}
      >
        {text}
      </Text>
    </FlexRowLayout>
  )
}
