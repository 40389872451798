import React, { ReactNode } from 'react'
import { Box, SxStyleProp } from 'rebass'

interface IHomeMaxWidth {
  sx?: SxStyleProp
  children: ReactNode
}

export const HomeMaxWidth = ({ children, sx }: IHomeMaxWidth) => (
  <Box sx={{ width: ['85%', '90%', 750, 950, 1072], display: 'flex', flexDirection: 'column', ...sx }}>{children}</Box>
)
