import React from 'react'
import { Box } from 'rebass'

export const generatePathName = (pageName: string) => {
  switch (pageName) {
    case 'About Us':
      return 'about'
    case 'Our Team':
      return 'team'
    case 'sba-loan-application':
      return 'sba-loan-application'
    default:
      return pageName.toLowerCase().replace(' ', '-')
  }
}

export const isExternalURL = (href: string | undefined): boolean => {
  if (href) {
    const firstFourLetters = href
      .split('')
      .slice(0, 4)
      .join('')
    return firstFourLetters === 'http'
  }
  return false
}

export const getTextWithLineBreaks = (text: string) =>
  text.split(/\n/).map((t, index, arr) => (
    <>
      <span>{t}</span>
      {index !== arr.length - 1 && <Box as="br" sx={{ height: 0, display: ['inline', 'inline'] }} />}
    </>
  ))

export const getDigitsFromNumber = (n: number): number[] => Array.from(n.toString()).map(Number)
