import React from 'react'
import { Box, Image, SxStyleProp } from 'rebass'
import { theme } from '..'
import Lottie from 'react-lottie'
import { Text, TTextTag } from '@northone/ui'

interface ITextBlockListProps {
  key: number
  imageSrc?: string
  imageAlt?: string
  title?: string
  paragraph?: string
  titleTag?: TTextTag | TTextTag[]
  titleSx?: SxStyleProp
  whiteText?: boolean
  verticallyCenterImage?: boolean
  horizontal?: boolean
  textColor?: string
  customItemSpacing?: (string | number)[] | string | number
  customIconHeight?: string[] | string
  paragraphElement?: React.ReactElement
  flexStart?: boolean
  customWidth?: string[] | string
  animatedIcon?: {}
  paragraphSx?: Record<string, string | number>
}

export const TextBlockList = ({
  verticallyCenterImage = true,
  imageSrc,
  imageAlt,
  title = '',
  paragraph = '',
  titleTag = 'h3',
  titleSx,
  whiteText = false,
  textColor,
  customItemSpacing,
  customIconHeight,
  paragraphElement,
  horizontal = false,
  customWidth,
  animatedIcon,
  paragraphSx,
}: ITextBlockListProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: horizontal ? ['auto', 'auto', '200px'] : 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: verticallyCenterImage ? 'center' : 'flex-start',
          flexDirection: horizontal ? ['row', 'row', 'column'] : 'row',
          marginY: customItemSpacing || '1.5rem',
          width: customWidth || ['80%', '80%', '100%'],
        }}
      >
        {animatedIcon && (
          <Box sx={{ height: 64, width: 64, flexShrink: 0 }}>
            <Lottie options={{ animationData: animatedIcon, loop: true, autoplay: true }} />
          </Box>
        )}
        {imageSrc && (
          <Image
            width={'auto'}
            height={'auto'}
            sx={{ minWidth: '70px', maxHeight: customIconHeight || '' }}
            src={imageSrc}
            alt={imageAlt}
          />
        )}
        <Box
          sx={{
            flexDirection: 'column',
            paddingLeft: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
            textAlign: horizontal ? [undefined, undefined, 'center'] : undefined,
          }}
        >
          <Text
            tag={titleTag}
            textColor={whiteText ? theme.colors.white : textColor ? textColor : theme.colors.blackPrimary}
            hasPartialBolding
            sx={{
              fontSize: '20px',
              lineHeight: '26px',
              paddingBottom: '5px',
              ...titleSx,
            }}
          >
            {title}
          </Text>
          {paragraphElement ? (
            paragraphElement
          ) : (
            <Text
              tag={['body', 'body', 'body', 'body', 'body-large']}
              textColor={whiteText ? theme.colors.white : textColor ? textColor : theme.colors.mediumGrey}
              style={paragraphSx}
            >
              {paragraph}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  )
}
