export enum COLORS {
  GREY = '#777676',
  BLUE = '#009BFF',
  NORTH_GREY = '#818D95',
  ORANGE = '#F2994A',
  BG_GREY = '#F7F7F7',
  BUTTON_GREY = '#D1D1D1',
  NAVY = '#24343E',
  WHITE = '#FFFFFF',
  LINK_GREY = '#4a4a4a',
  BOX_SHADOW_GREY = '#EDF2F8',
  CARD_IMAGE_CONTAINER_PURPLE = '#6772E5',
  CARD_IMAGE_CONTAINER_BRIGHT_BLUE = '#00BDF2',
  CARD_IMAGE_CONTAINER_RED = '#FF6060',
  CARD_IMAGE_CONTAINER_GREY = '#818D95',
  CARD_IMAGE_CONTAINER_LIGHT_BLUE = '#DAF0FF',
  CARD_IMAGE_CONTAINER_LIGHT_GREEN = '#DAFFF3',
  BG_NAVY = '#24343E',
  BG_CORAL = '#E0F7F7',
}
