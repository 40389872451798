import { PATHS } from './paths'

export const PAGES: IPages = {
  HOME: {
    HEAD_KEY: 'Home',
    TITLE: 'NorthOne: Small Business Banking Made Simple',
    DESCRIPTION:
      'NorthOne is an online Business Account with built-in features to make business banking fast and easy. No overdraft fees. No minimum balance. No hidden fees.',
    PATH: PATHS.HOME,
    BREADCRUMBS: ['Home'],
  },
  ABOUT: {
    MAIN: {
      HEAD_KEY: 'About',
      TITLE: 'About | NorthOne',
      DESCRIPTION:
        "NorthOne is a business banking platform for small businesses, startups and freelancers. We're on a mission to change the way that small businesses think about banking and finance.",
      PATH: PATHS.ABOUT,
      BREADCRUMBS: ['Home', 'About'],
    },
    CAREERS: {
      HEAD_KEY: 'Careers',
      TITLE: 'Careers | NorthOne',
      DESCRIPTION:
        'NorthOne is dedicated to changing banking for small businesses and freelancers. Join our team in making financial management easy and affordable.',
      PATH: PATHS.CAREERS,
      BREADCRUMBS: ['Home', 'About', 'Careers'],
    },
    TEAM: {
      MAIN: {
        HEAD_KEY: 'Team',
        TITLE: 'Team | NorthOne',
        DESCRIPTION:
          'The NorthOne team is made up of top developers, designers and leaders working to build better banking products.',
        PATH: PATHS.TEAM,
        BREADCRUMBS: ['Home', 'About', 'Team'],
      },
      EYTAN: {
        HEAD_KEY: 'Eytan',
        TITLE: 'Eytan Bensoussan | NorthOne',
        DESCRIPTION: 'Eytan Bensoussan is the Co-Founder and CEO of NorthOne.',
        PATH: PATHS.EYTAN,
        BREADCRUMBS: ['Home', 'About', 'Team', 'Eytan'],
      },
      JUSTIN: {
        HEAD_KEY: 'Justin',
        TITLE: 'Justin Adler | NorthOne',
        DESCRIPTION: 'Justin Adler is the Co-Founder and COO of NorthOne.',
        PATH: PATHS.JUSTIN,
        BREADCRUMBS: ['Home', 'About', 'Team', 'Justin'],
      },
      BLAKE: {
        HEAD_KEY: 'Blake',
        TITLE: 'Blake Edwards | NorthOne',
        DESCRIPTION: 'Blake Edwards is the VP of Engineering of NorthOne.',
        PATH: PATHS.BLAKE,
        BREADCRUMBS: ['Home', 'About', 'Team', 'Blake'],
      },
    },
  },
  OUR_CUSTOMERS: {
    MAIN: {
      HEAD_KEY: 'Our Customers',
      TITLE: 'Our Customers | NorthOne',
      DESCRIPTION: 'NorthOne provides business banking for small businesses, freelancers and startups.',
      PATH: PATHS.OUR_CUSTOMERS,
      BREADCRUMBS: ['Home', 'Our Customers'],
    },
    SMALL_BUSINESS: {
      HEAD_KEY: 'Small Business',
      TITLE: 'Small Business Banking | NorthOne',
      DESCRIPTION:
        'NorthOne simplifies business banking for small businesses. Manage your business account online through the NorthOne app.',
      PATH: PATHS.SMALL_BUSINESS,
      BREADCRUMBS: ['Home', 'Our Customers', 'Small Business'],
    },
    FREELANCER: {
      HEAD_KEY: 'Freelancer',
      TITLE: 'Banking for Freelancers | NorthOne',
      DESCRIPTION:
        'NorthOne offers business bank accounts for freelancers. Simple banking and tools that empower freelancers to easily manage their finances and get back to business.',
      PATH: PATHS.FREELANCER,
      BREADCRUMBS: ['Home', 'Our Customers', 'Freelancer'],
    },
    STARTUP: {
      HEAD_KEY: 'Startups',
      TITLE: 'Banking for Startups | NorthOne',
      DESCRIPTION:
        'NorthOne business bank accounts provide startups with the tools they need to manage and scale their business. NorthOne empowers entrepreneurs to take charge of their business’s financial health.',
      PATH: PATHS.STARTUP,
      BREADCRUMBS: ['Home', 'Our Customers', 'Startup'],
    },
  },
  PRODUCT: {
    MAIN: {
      HEAD_KEY: 'Product',
      TITLE: 'Product | NorthOne',
      DESCRIPTION:
        'NorthOne offers fully mobile digital bank accounts for businesses. The NorthOne app provides small businesses, startups and freelancers tools to easily manage their business accounts.',
      PATH: PATHS.PRODUCT,
      BREADCRUMBS: ['Home', 'Product'],
    },
    FEATURES: {
      HEAD_KEY: 'Features',
      TITLE: 'Features | NorthOne',
      DESCRIPTION: 'NorthOne offers mobile business banking, team banking capabilities fully transparent pricing.',
      PATH: PATHS.FEATURES,
      BREADCRUMBS: ['Home', 'Product', 'Features'],
    },
    PRICING: {
      HEAD_KEY: 'Pricing',
      TITLE: 'Pricing | NorthOne',
      DESCRIPTION: 'NorthOne’s pricing is $10 per month with no hidden fees.',
      PATH: PATHS.PRICING,
      BREADCRUMBS: ['Home', 'Product', 'Pricing'],
    },
    INTEGRATIONS: {
      MAIN: {
        HEAD_KEY: 'Integrations',
        TITLE: 'Integrations | NorthOne',
        DESCRIPTION:
          'NorthOne integrates easily with your business finance tools. Integrate your NorthOne account with Shopify, Quickbooks, Xero and more.',
        PATH: PATHS.INTEGRATIONS,
        BREADCRUMBS: ['Home', 'Product', 'Integrations'],
      },
      AMAZON: {
        HEAD_KEY: 'Amazon',
        TITLE: 'Amazon | NorthOne',
        DESCRIPTION:
          'NorthOne integrates with Amazon eCommerce software. Connect your business bank account with Amazon and seamlessly manage deposits, order numbers and more.',
        PATH: PATHS.AMAZON,
        BREADCRUMBS: ['Home', 'Product', 'Integrations', 'Amazon'],
      },
      EXPENSIFY: {
        HEAD_KEY: 'Expensify',
        TITLE: 'Expensify | NorthOne',
        DESCRIPTION:
          'Integrate your business bank account with Expensify. NorthOne syncs with Expensify so you can manage your accounting data in one place.',
        PATH: PATHS.EXPENSIFY,
        BREADCRUMBS: ['Home', 'Product', 'Integrations', 'Expensify'],
      },
      FRESHBOOKS: {
        HEAD_KEY: 'Freshbooks',
        TITLE: 'Freshbooks | NorthOne',
        DESCRIPTION:
          'Integrate your business bank account with Freshbooks. NorthOne syncs with Freshbooks so you can manage your accounting data in one place.',
        PATH: PATHS.FRESHBOOKS,
        BREADCRUMBS: ['Home', 'Product', 'Integrations', 'Freshbooks'],
      },
      PAYPAL: {
        HEAD_KEY: 'Paypal',
        TITLE: 'Paypal | NorthOne',
        DESCRIPTION:
          'NorthOne integrates with Paypal payment processing software. Connect your business bank account with Paypal and seamlessly manage your payouts.',
        PATH: PATHS.PAYPAL,
        BREADCRUMBS: ['Home', 'Product', 'Integrations', 'Paypal'],
      },
      QUICKBOOKS: {
        HEAD_KEY: 'QuickBooks',
        TITLE: 'QuickBooks | NorthOne',
        DESCRIPTION:
          'Integrate your business bank account with QuickBooks. NorthOne syncs with QuickBooks so you can manage your accounting data in one place.',
        PATH: PATHS.QUICKBOOKS,
        BREADCRUMBS: ['Home', 'Product', 'Integrations', 'Quickbooks'],
      },
      SHOPIFY: {
        HEAD_KEY: 'Shopify',
        TITLE: 'Shopify | NorthOne',
        DESCRIPTION:
          'NorthOne integrates with Shopify eCommerce software. Connect your business bank account with Shopify and seamlessly manage deposits, order numbers and more.',
        PATH: PATHS.SHOPIFY,
        BREADCRUMBS: ['Home', 'Product', 'Integrations', 'Shopify'],
      },
      STRIPE: {
        HEAD_KEY: 'Stripe',
        TITLE: 'Stripe | NorthOne',
        DESCRIPTION:
          'NorthOne integrates with Stripe payment processing software. Connect your business bank account with Stripe and seamlessly manage your payouts.',
        PATH: PATHS.STRIPE,
        BREADCRUMBS: ['Home', 'Product', 'Integrations', 'Stripe'],
      },
      WAVE: {
        HEAD_KEY: 'Wave',
        TITLE: 'Wave | NorthOne',
        DESCRIPTION:
          'Integrate your business bank account with Wave. NorthOne syncs with Wave so you can manage your accounting data in one place.',
        PATH: PATHS.WAVE,
        BREADCRUMBS: ['Home', 'Product', 'Integrations', 'Wave'],
      },
      // XERO: {
      //   HEAD_KEY: 'Xero',
      //   TITLE: 'Xero | NorthOne',
      //   DESCRIPTION:
      //     'Integrate your business bank account with Xero. NorthOne syncs with Xero so you can manage your accounting data in one place.',
      //   PATH: PATHS.XERO,
      //   BREADCRUMBS: ['Home', 'Product', 'Integrations', 'Xero'],
      // },
    },
    GETTING_STARTED: {
      HEAD_KEY: 'Getting Started',
      TITLE: 'Getting Started | NorthOne',
      DESCRIPTION:
        'Get started with a NorthOne bank account. Sign up is simple for interested customers, learn what you need to open your business bank account today.',
      PATH: PATHS.GETTING_STARTED,
      BREADCRUMBS: ['Home', 'Product', 'Getting Started'],
    },
  },
  CONTACT_US: {
    HEAD_KEY: 'Contact Us',
    TITLE: 'Contact Us | NorthOne',
    DESCRIPTION:
      'Contact NorthOne during available customer service hours to receive support for all of your banking needs.',
    PATH: PATHS.CONTACT_US,
    BREADCRUMBS: ['Home', 'About', 'Contact Us'],
  },
  FAQ: {
    HEAD_KEY: 'FAQ',
    TITLE: 'FAQ | NorthOne',
    DESCRIPTION:
      'Is my account FDIC insured? How long do deposits take to clear? NorthOne can offer support for your pressing questions.',
    PATH: PATHS.FAQ,
    BREADCRUMBS: ['Home', 'FAQ'],
  },
  OPEN_BANKING: {
    HEAD_KEY: 'Open Banking',
    TITLE: 'Open Banking | NorthOne',
    DESCRIPTION:
      'NorthOne offers the first API banking platform for small businesses. NorthOne’s open banking makes financial management accessible and affordable.',
    PATH: PATHS.OPEN_BANKING,
    BREADCRUMBS: ['Home', 'Open Banking'],
  },
  PRIVACY_POLICY: {
    HEAD_KEY: 'NorthOne Privacy Policy',
    TITLE: 'NorthOne Privacy Policy | NorthOne',
    DESCRIPTION:
      'We developed this privacy policy in order for you to understand how we collect, use, communicate, disclose and otherwise make use of personal information.',
    PATH: PATHS.PRIVACY_POLICY,
    BREADCRUMBS: ['Home', 'NorthOne Privacy Policy'],
  },
  PRIVACY_POLICY_BC: {
    HEAD_KEY: 'Bancorp Privacy Policy',
    TITLE: 'Bancorp Privacy Policy | NorthOne',
    DESCRIPTION:
      'We developed this privacy policy in order for you to understand how The Bancorp Inc. collect, use, communicate, disclose and otherwise make use of personal information.',
    PATH: PATHS.PRIVACY_POLICY_BC,
    BREADCRUMBS: ['Home', 'Bancorp Privacy Policy'],
  },
  ACCOUNT_AGREEMENT: {
    HEAD_KEY: 'Account Agreement',
    TITLE: 'Account Agreement | NorthOne',
    DESCRIPTION:
      'The NorthOne Business Account Agreement details the terms of the deposit account agreement including account basics, responsibilities, and other details.',
    PATH: PATHS.ACCOUNT_AGREEMENT,
    BREADCRUMBS: ['Home', 'Account Agreement'],
  },
  ELECTRONIC_AGREEMENT: {
    HEAD_KEY: 'Electronic Communications Agreement',
    TITLE: 'Electronic Communications Agreement | NorthOne',
    DESCRIPTION:
      'The Agreement to Receive Electronic Communications document details electronic communications and information NorthOne customers consent to receive.',
    PATH: PATHS.ELECTRONIC_AGREEMENT,
    BREADCRUMBS: ['Home', 'Electronic Communications Agreement'],
  },
  SECURITY: {
    HEAD_KEY: 'Security',
    TITLE: 'Security | NorthOne',
    DESCRIPTION:
      'Keep your business bank account and data secure with NorthOne. All accounts are FDIC insured and protected under NorthOne’s security policy.',
    PATH: PATHS.SECURITY,
    BREADCRUMBS: ['Home', 'Security'],
  },
  PARTNERSHIPS: {
    HEAD_KEY: 'Partnerships',
    TITLE: 'Become A NorthOne Business Partner | NorthOne',
    DESCRIPTION:
      'NorthOne is a business banking platform for small businesses, startups and freelancers. Join our affiliate program and earn great commissions by promoting us to your audience.',
    PATH: PATHS.PARTNERSHIPS,
    BREADCRUMBS: ['Home', 'Partners'],
  },
  DEVELOPMENT: {
    TYPOGRAPHY: {
      HEAD_KEY: 'Typography',
      TITLE: 'Typography | NorthOne',
      DESCRIPTION: 'Development reference page for typography used throughout the website',
      PATH: PATHS.ABOUT,
      BREADCRUMBS: ['Development', 'Typography'],
    },
    MODULES: {
      HEAD_KEY: 'Modules',
      TITLE: 'Modules | NorthOne',
      DESCRIPTION: 'Development reference page for modules used throughout the website',
      PATH: PATHS.ABOUT,
      BREADCRUMBS: ['Development', 'Modules'],
    },
  },
}

interface IPages {
  HOME: IPage
  ABOUT: {
    MAIN: IPage
    CAREERS: IPage
    TEAM: {
      MAIN: IPage
      EYTAN: IPage
      JUSTIN: IPage
      BLAKE: IPage
    }
  }
  OUR_CUSTOMERS: {
    MAIN: IPage
    SMALL_BUSINESS: IPage
    FREELANCER: IPage
    STARTUP: IPage
  }
  PRODUCT: {
    MAIN: IPage
    FEATURES: IPage
    PRICING: IPage
    INTEGRATIONS: {
      MAIN: IPage
      AMAZON: IPage
      EXPENSIFY: IPage
      FRESHBOOKS: IPage
      PAYPAL: IPage
      QUICKBOOKS: IPage
      SHOPIFY: IPage
      STRIPE: IPage
      WAVE: IPage
      // XERO: IPage
    }
    GETTING_STARTED: IPage
  }
  CONTACT_US: IPage
  FAQ: IPage
  OPEN_BANKING: IPage
  PRIVACY_POLICY: IPage
  PRIVACY_POLICY_BC: IPage
  ACCOUNT_AGREEMENT: IPage
  ELECTRONIC_AGREEMENT: IPage
  SECURITY: IPage
  PARTNERSHIPS: IPage
  DEVELOPMENT: {
    TYPOGRAPHY: IPage
    MODULES: IPage
  }
}

export interface IPage {
  HEAD_KEY: string
  TITLE: string
  DESCRIPTION: string
  PATH: string
  BREADCRUMBS: TBreadcrumb[]
}

export type TBreadcrumb =
  | 'Home'
  | 'About'
  | 'Team'
  | 'Eytan'
  | 'Justin'
  | 'Blake'
  | 'Careers'
  | 'Our Customers'
  | 'Small Business'
  | 'Freelancer'
  | 'Startup'
  | 'Product'
  | 'Features'
  | 'Pricing'
  | 'Integrations'
  | 'Amazon'
  | 'Quickbooks'
  | 'Paypal'
  | 'Wave'
  | 'Freshbooks'
  | 'Xero'
  | 'Stripe'
  | 'Shopify'
  | 'Expensify'
  | 'Getting Started'
  | 'Open Banking'
  | 'Security'
  | 'NorthOne Privacy Policy'
  | 'Bancorp Privacy Policy'
  | 'Account Agreement'
  | 'Electronic Communications Agreement'
  | 'Contact Us'
  | 'Invoice Maker'
  | 'FAQ'
  | 'Partners'
  | 'Development'
  | 'Typography'
  | 'Modules'
