import React from 'react'
import { Box, SxStyleProp } from 'rebass'
import {
  Button as N1Button,
  ButtonTypeEnum,
  TTextTag,
  Text as N1Text,
  /*TextWithHighlight,*/ theme as n1Theme,
} from '@northone/ui'
import { theme } from '../styles/theme'
import { isExternalURL } from '../../helper'
import { Text } from '../../shared'
import { TextWithHighlight } from '../../shared/components/text-with-highlight'
import { CTAButton, IProps } from './v2/CtaButton/ctaButton'

export interface IButtonProps {
  text: string
  url?: string
  type?: ButtonTypeEnum
  onClick?: () => void
  buttonWidth?: (string | number)[]
  backgroundColor?: string
  textColor?: string
  fontFamily?: string
  sx?: SxStyleProp
  buttonTextSx?: SxStyleProp
}

export interface ITextBlockProps {
  title?: JSX.Element | string | null
  titleTag?: TTextTag | TTextTag[]
  paragraph?: string
  paragraphTag?: TTextTag | TTextTag[]
  button?: IButtonProps | null
  secondButton?: IButtonProps | null
  ctaButton?: IProps | null
  subContent?: JSX.Element
  paragraphJSX?: JSX.Element
  contentBlockJSX?: JSX.Element[] | JSX.Element
  inverted?: boolean
  textColor?: string
  paragraphColor?: string
  centered?: boolean | boolean[]
  animateText?: boolean
  titleStyle?: SxStyleProp
  paragraphStyle?: SxStyleProp
  leftAlignText?: boolean
  containerSx?: SxStyleProp
  label?: string
  titleContainerSx?: SxStyleProp
  buttonContainerSx?: SxStyleProp
  titleHighlightColor?: string
  contentBlockSx?: SxStyleProp
  hasSuperScript?: string
}

export const handleButtonClick = ({ onClick, url }: { onClick?: () => void; url?: string }) => {
  if (onClick) {
    onClick()
  }
  if (url) {
    window.open(url, isExternalURL(url) ? '_blank' : '_self')
  }
}

export const HOME_SCREEN_BUTTON_WIDTH = [275, 275, 215]

export const TextBlock = ({
  title,
  paragraph,
  paragraphJSX,
  contentBlockJSX,
  button,
  secondButton,
  ctaButton,
  subContent,
  titleTag = 'h1',
  textColor,
  paragraphColor,
  centered,
  titleStyle,
  paragraphStyle,
  leftAlignText,
  containerSx,
  paragraphTag = ['body', 'body', 'body', 'body-large'],
  label,
  titleContainerSx,
  buttonContainerSx,
  contentBlockSx,
  hasSuperScript,
}: ITextBlockProps): JSX.Element => {
  const getTextAlignmentFromCenteredValue = (centeredValue: boolean[] | boolean | undefined) => {
    if (Array.isArray(centeredValue)) {
      return centeredValue.map(item => (item ? 'center' : 'left'))
    }
    return centered ? 'center' : ['center', 'center', 'left']
  }

  return (
    <Box sx={{ ...containerSx, display: 'flex', flexDirection: 'column' }}>
      {label && (
        <Text
          tag="h5"
          sx={{
            ...theme.fontstyles.eyeBrowText,
            marginBottom: 20,
            paddingLeft: '2px',
            textAlign: leftAlignText ? 'left' : getTextAlignmentFromCenteredValue(centered),
          }}
        >
          {label}
        </Text>
      )}
      <Box
        sx={{
          marginBottom: 15,
          ...titleContainerSx,
        }}
      >
        <TextWithHighlight
          highlightColor={'transparent'}
          centered={leftAlignText ? false : centered ? centered : [true, true, false]}
          sx={{
            color: textColor ? textColor : theme.colors.ui1,
            ...titleStyle,
          }}
          tag={titleTag}
          superScript={hasSuperScript}
        >
          {title}
        </TextWithHighlight>
      </Box>
      {paragraph && (
        <N1Text
          tag={paragraphTag}
          sx={{
            color: paragraphColor ? paragraphColor : textColor ? textColor : theme.colors.ui1,
            textAlign: leftAlignText ? 'left' : getTextAlignmentFromCenteredValue(centered),
            ...paragraphStyle,
          }}
        >
          {paragraph}
        </N1Text>
      )}
      {paragraphJSX ? paragraphJSX : ''}
      <Box sx={{ ...contentBlockSx }}>{contentBlockJSX ? contentBlockJSX : ''}</Box>
      {button ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: ['center', 'center', centered ? 'center' : 'start'],
            marginTop: theme.verticalElementSpacing,
            ...buttonContainerSx,
          }}
        >
          <N1Button
            onClick={() => handleButtonClick({ onClick: button.onClick, url: button.url })}
            sx={{ ...button.sx }}
          >
            <Text tag={'p'} sx={button.buttonTextSx}>
              {button.text}
            </Text>
          </N1Button>
        </Box>
      ) : null}
      {secondButton ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: ['center', 'center', centered ? 'center' : 'start'],
            marginTop: 10,
          }}
        >
          <N1Button
            onClick={() => handleButtonClick({ onClick: secondButton.onClick, url: secondButton.url })}
            type={secondButton.type}
            sx={{ width: secondButton.buttonWidth || HOME_SCREEN_BUTTON_WIDTH }}
          >
            {secondButton.text}
          </N1Button>
        </Box>
      ) : null}
      {ctaButton ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: ['center', 'center', centered ? 'center' : 'start'],
            marginTop: theme.verticalElementSpacing,
            ...buttonContainerSx,
          }}
        >
          <CTAButton
            text={ctaButton.text}
            type={ctaButton.type}
            onClick={() => handleButtonClick({ onClick: ctaButton.onClick, url: ctaButton.url })}
            buttonSx={{ ...ctaButton.buttonSx }}
          />
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column'],
          justifyContent: ['center', 'center', 'start'],
        }}
      >
        {subContent ? subContent : ''}
      </Box>
    </Box>
  )
}

export const TextBlockWithLargeParagraph = (props: ITextBlockProps) => (
  <TextBlock
    {...props}
    paragraphTag={['body-large', 'body-large', 'body-large', 'body-large', 'body-x-large']}
    paragraphStyle={{
      fontSize: [
        n1Theme.textStyles['body-large'].fontSize,
        n1Theme.textStyles['body-large'].fontSize,
        n1Theme.textStyles['body-large'].fontSize,
        n1Theme.textStyles['body-large'].fontSize,
        '20px',
      ],
      lineHeight: [
        n1Theme.textStyles['body-large'].lineHeight,
        n1Theme.textStyles['body-large'].lineHeight,
        n1Theme.textStyles['body-large'].lineHeight,
        n1Theme.textStyles['body-large'].lineHeight,
        '28px',
      ],
      ...props.paragraphStyle,
    }}
  />
)
