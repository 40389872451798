import React, { ReactNode } from 'react'
import { Text as RText, SxStyleProp, TextProps as RTextProps } from 'rebass'
import { theme } from '../styles/theme'
import { getTextWithLineBreaks } from '../../helper'

interface IProps extends RTextProps {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p'
  sx?: SxStyleProp
  children: ReactNode
}

export const Text = (props: IProps) => {
  const { tag = 'p', sx, children } = props
  return (
    <RText
      as={tag}
      {...props}
      sx={{
        color: theme.colors.mediumGrey,
        ...theme.fontstyles[tag],
        ...sx,
      }}
    >
      {typeof children === 'string' ? getTextWithLineBreaks(children) : children}
    </RText>
  )
}
