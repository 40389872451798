import React from 'react'
import { Box, Link } from 'rebass'
import { TBreadcrumb, PATHS } from '../../utils'
import { StandardPadding } from '../layout'
import { theme } from '..'

interface IBreadcrumbsProps {
  breadcrumbs: TBreadcrumb[]
}

export const getBreadcrumbPath = (breadcrumb: TBreadcrumb): string => {
  const formattedBreadcrumb = breadcrumb.toUpperCase().replace(' ', '_') as keyof typeof PATHS
  return PATHS[formattedBreadcrumb]
}

export const Breadcrumbs = ({ breadcrumbs }: IBreadcrumbsProps) => {
  const whiteSpace = '   '
  return (
    <StandardPadding>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: theme.contentMaximumWidth,
          marginTop: [90, 90, 60],
          marginBottom: -50,
          whiteSpace: 'pre-wrap',
          zIndex: 2,
        }}
      >
        {breadcrumbs.map((breadcrumb, i) => {
          return i < breadcrumbs.length - 1 ? (
            <div key={i}>
              <Link sx={{ color: theme.colors.blackPrimary }} href={getBreadcrumbPath(breadcrumb)}>
                {`${i === 0 ? '' : whiteSpace}${breadcrumb}`}
              </Link>
              <span style={{ cursor: 'default' }}>{`${whiteSpace}›`}</span>
            </div>
          ) : (
            <span
              style={{
                color: theme.colors.blackPrimary,
                fontWeight: 'bold',
                cursor: 'default',
              }}
              key={i}
            >{`${whiteSpace}${breadcrumb}`}</span>
          )
        })}
      </Box>
    </StandardPadding>
  )
}
