import React, { useState } from 'react'
import { Image, Box } from 'rebass'
import { motion, useAnimation } from 'framer-motion'
import { Waypoint } from 'react-waypoint'

interface IAnimatedATMPopupProps {
  animationBottomOffset: string
}

export const AnimatedATMPopup = ({ animationBottomOffset }: IAnimatedATMPopupProps) => {
  const [hasAnimated, setHasAnimated] = useState(false)
  const controls = useAnimation()

  return (
    <Waypoint
      bottomOffset={animationBottomOffset}
      onEnter={() => {
        if (!hasAnimated) {
          controls.start({ height: '100%', opacity: 1, width: '100%' })
          setHasAnimated(true)
        }
      }}
    >
      <Box
        sx={{
          alignSelf: 'center',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-end',
          height: [undefined, undefined, 150, 190],
          width: '100%',
          paddingLeft: [20, 20, 45],
          maxWidth: [375, 375, 'none'],
        }}
      >
        <motion.div
          style={{
            height: '10%',
            width: '10%',
            opacity: 0,
          }}
          animate={controls}
        >
          <Image
            src="/static/images/homepage/atm-popup-dark.png"
            sx={{
              alignSelf: 'center',
              height: ['auto', 'auto', '100%'],
              width: ['100%', '100%', 'auto'],
            }}
          />
        </motion.div>
      </Box>
    </Waypoint>
  )
}
