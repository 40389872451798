import React from 'react'
import { TTextTag } from '@northone/ui'
import { fireWebsiteSignupEvent } from '../../utils'
import { ONBOARDING_URL } from '../../utils/urls'
// import { theme } from '..'
import { SxStyleProp } from 'rebass'
// import { theme } from '..'
import { CTAButton } from './v2/CtaButton/ctaButton'

interface IOpenAccountButtonProps {
  tag?: TTextTag | TTextTag[]
  buttonSx?: SxStyleProp
  buttonTextSx?: SxStyleProp
}

export const OpenAccountButton: React.FC<IOpenAccountButtonProps> = (props: IOpenAccountButtonProps) => {
  const { buttonSx } = props
  const handleClick = () => {
    window.open(ONBOARDING_URL, '_blank')
    fireWebsiteSignupEvent()
  }

  return (
    <CTAButton
      text={'Apply for an account'}
      type={'blackPrimary'}
      onClick={handleClick}
      buttonSx={{
        ...buttonSx,
      }}
    />
  )
}
