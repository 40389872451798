import React from 'react'
import { Text as RText, Box, TextProps as RTextProps, SxStyleProp } from 'rebass'
import './fonts/fonts.css'
import { theme } from './theme'

interface ITextStyle {
  fontSize: string
  lineHeight: string
  fontWeight: number | string
  fontFamily: string
  letterSpacing: string
  textTransform: string
}

export type TTextTag =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h3Serif'
  | 'h4'
  | 'h5'
  | 'body'
  | 'body-bold'
  | 'body-small'
  | 'body-small-bold'
  | 'body-x-small'
  | 'body-large'
  | 'body-large-bold'
  | 'body-x-large'
  | 'label'
  | 'label-bold'
  | 'amount-input'

export interface ITextProps extends RTextProps {
  tag: TTextTag | TTextTag[]
  textColor?: string
  textRef?: React.RefObject<HTMLDivElement>
  hasPartialBolding?: boolean
  partialStyling?: React.CSSProperties
}

interface IGetTextWithLineBreaks {
  text: string
  hasPartialBolding?: boolean
  partialStyling?: React.CSSProperties
}

// This makes text wrapped in inside *'s *BOLD*
export const getBoldedAndNonBoldedText = (text: string) =>
  text.split('*').map((word, index) =>
    index % 2 ? (
      <strong key={index} style={{ fontWeight: 500 }}>
        {word}
      </strong>
    ) : (
      word
    ),
  )

export const getPartiallyStyledText = (text: string, partialStyling: React.CSSProperties) =>
  text.split('**').map((word, index) =>
    index % 2 ? (
      <Box as={'span'} key={index} style={partialStyling}>
        {word}
      </Box>
    ) : (
      word
    ),
  )

export const getTextWithLineBreaks = ({ text, hasPartialBolding, partialStyling }: IGetTextWithLineBreaks) =>
  text.split(/\n/).map((text, index, arr) => (
    <React.Fragment key={index}>
      <span>
        {hasPartialBolding
          ? getBoldedAndNonBoldedText(text)
          : partialStyling
          ? getPartiallyStyledText(text, partialStyling)
          : text}
      </span>
      {index !== arr.length - 1 && <Box as="br" sx={{ height: 0, display: ['inline', 'inline'] }} />}
    </React.Fragment>
  ))

export const designSystemFontToTag: { [key in TTextTag]: React.ElementType } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h3Serif: 'h3',
  h4: 'h4',
  h5: 'h5',
  'body-x-large': 'p',
  'body-large': 'p',
  body: 'p',
  'body-small': 'p',
  'body-large-bold': 'p',
  'body-bold': 'p',
  'body-small-bold': 'p',
  label: 'p',
  'label-bold': 'p',
  'amount-input': 'p',
  'body-x-small': 'p',
}

const getStyleFromTag = (
  tag: TTextTag | TTextTag[],
  style: keyof ITextStyle,
): string | number | undefined | (string | number | undefined)[] =>
  typeof tag === 'string'
    ? theme.textStyles[tag][style]
    : tag.map(breakpointTag => theme.textStyles[breakpointTag][style])

/**
 * Defines a primitive text component - Must be extended
 * Primitive Text defines typography and font styles per design system
 * @param props.children
 */
export const Text = ({
  children,
  tag,
  textColor,
  sx,
  textRef,
  hasPartialBolding,
  partialStyling,
  ...defaultTextProps
}: ITextProps) => {
  return (
    <RText
      as={typeof tag === 'string' ? designSystemFontToTag[tag] : designSystemFontToTag[tag[tag.length - 1]]}
      sx={
        {
          color: textColor,
          fontFamily: getStyleFromTag(tag, 'fontFamily'),
          lineHeight: getStyleFromTag(tag, 'lineHeight'),
          fontWeight: getStyleFromTag(tag, 'fontWeight'),
          fontSize: getStyleFromTag(tag, 'fontSize'),
          letterSpacing: getStyleFromTag(tag, 'letterSpacing'),
          textTransform: getStyleFromTag(tag, 'textTransform'),
          ...sx,
        } as SxStyleProp
      }
      ref={textRef}
      {...defaultTextProps}
    >
      {typeof children === 'string'
        ? getTextWithLineBreaks({ text: children, hasPartialBolding, partialStyling })
        : children}
    </RText>
  )
}
