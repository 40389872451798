import React, { ReactNode, useEffect, useState } from 'react'
import { Box, SxStyleProp } from 'rebass'
import { motion, useAnimation } from 'framer-motion'

import FontFaceObserver from 'fontfaceobserver'

const fontA = new FontFaceObserver('Graphik')
const fontB = new FontFaceObserver('Plantin-MT-Pro-Light')

export interface IWithHighlightProps {
  horizontalPadding?: number
  color: string
  hidden?: boolean
  positionBottomScale?: number
  heightScale?: number
  sx?: SxStyleProp
  children: ReactNode
}

export const WithHighlight = ({
  children,
  color,
  horizontalPadding = 4,
  hidden = false,
  positionBottomScale = 0.15,
  heightScale = 0.35,
  sx,
}: IWithHighlightProps) => {
  const childrenRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const [childrenHeight, setChildrenHeight] = useState(0)
  const [childrenWidth, setChildrenWidth] = useState(0)
  const setDimensions = () => {
    const boundingRect = childrenRef.current && childrenRef.current.getBoundingClientRect()
    setChildrenWidth(boundingRect ? boundingRect.width : 0)
    setChildrenHeight(boundingRect ? boundingRect.height : 0)
  }
  useEffect(() => {
    setDimensions()
    Promise.all([fontA.load(), fontB.load()]).then(setDimensions)
  }, [])
  useEffect(setDimensions, [children])

  useEffect(() => {
    window.addEventListener('resize', setDimensions)
    return () => {
      window.addEventListener('resize', setDimensions)
    }
  }, [])

  const controls = useAnimation()

  useEffect(() => {
    if (hidden) {
      controls.start({ opacity: 0 }).then(() => controls.set({ scaleX: 0 }))
    } else {
      controls.set({ opacity: 1 })
      controls.start({ scaleX: 1 })
    }
  }, [hidden, controls])
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', zIndex: 1, ...sx }}>
      <Box sx={{ display: 'inline' }} ref={childrenRef}>
        {children}
      </Box>
      <motion.div
        initial={{ scaleX: hidden ? 0 : 1, opacity: hidden ? 0 : 1 }}
        style={{
          position: 'absolute',
          backgroundColor: color,
          bottom: childrenHeight * positionBottomScale,
          zIndex: -1,
          height: childrenHeight * heightScale,
          width: `${horizontalPadding * 2 + childrenWidth}px`,
          left: `-${horizontalPadding}px`,
        }}
        transition={{ type: 'spring', stiffness: 550, damping: 40, mass: 1.4 }}
        animate={controls}
      ></motion.div>
    </Box>
  )
}
