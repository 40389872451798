import React from 'react'
import { Box, Image, SxStyleProp } from 'rebass'
import { theme } from '..'
import { Text, TTextTag } from '@northone/ui'

export interface ICardProps {
  titleTag?: TTextTag
  paragraphTag?: TTextTag
  title: string
  paragraph?: string
  imageSrc?: string
  imageAlt?: string
  imageBackgroundColor?: string
  imageText?: string
  marginTop?: string | (string | undefined)[]
  alignImage?: 'flex-start' | 'flex-end' | 'center' | 'right'
  height?: string | string[]
  imageWidth?: string | number | (string | number)[]
  imageOnBackground?: boolean
  alignItems?: string | string[]
  maxWidth?: string | string[]
  marginX?: string | string[]
  marginY?: string | string[]
  cardStyles?: SxStyleProp
  imageStyles?: SxStyleProp
  textStyles?: SxStyleProp
  titleStyles?: SxStyleProp
  paragraphStyles?: SxStyleProp
}

export const Card = ({
  titleTag = 'body-bold',
  paragraphTag = 'body',
  title = '',
  paragraph = '',
  imageSrc = '',
  imageAlt = '',
  imageBackgroundColor = '',
  imageText,
  alignImage = 'right',
  height = '335px',
  imageOnBackground = false,
  maxWidth = '262px',
  imageWidth = '100%',
  alignItems = 'flex-start',
  marginX = '10px',
  marginY = '20px',
  cardStyles,
  imageStyles,
  textStyles,
  titleStyles,
  paragraphStyles,
}: ICardProps) => {
  return (
    <Box
      sx={{
        borderRadius: '4px',
        marginX,
        marginY,
        paddingY: '10px',
        maxWidth,
        height,
        display: 'flex',
        flexDirection: 'column',
        alignItems,
        bg: imageBackgroundColor,
        ...cardStyles,
      }}
    >
      <Image
        sx={{
          height: imageOnBackground ? '90px' : '112px',
          width: 'auto',
          ...imageStyles,
        }}
        src={imageSrc}
        alt={imageAlt}
      />
      <Box
        sx={{
          padding: '20px',
          ...textStyles,
        }}
      >
        <Box
          sx={{
            width: imageWidth,
            display: 'flex',
            justifyContent: alignImage,
            overflow: 'hidden',
            alignItems: 'center',
            position: 'relative',
            flexShrink: 0,
          }}
        >
          {imageText && (
            <Text
              tag="body-large"
              // eslint-disable-next-line react/no-children-prop
              children={imageText}
              sx={{
                color: theme.colors.ui1,
                paddingBottom: '10px',
                fontWeight: 'bolder',
                ...theme.fontStyles[2],
              }}
            />
          )}
        </Box>
        <Text
          tag={titleTag}
          // eslint-disable-next-line react/no-children-prop
          children={title}
          sx={{
            color: theme.colors.ui1,
            paddingBottom: '10px',
            ...theme.fontStyles[1],
            ...titleStyles,
          }}
        />
        {/*eslint-disable-next-line react/no-children-prop*/}
        <Text tag={paragraphTag} sx={paragraphStyles} textColor={theme.colors.ui2} children={paragraph}></Text>
      </Box>
    </Box>
  )
}
