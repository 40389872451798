import React, { useEffect } from 'react'
import { Button as RButton, Link, SxStyleProp } from 'rebass'
import { theme, Text } from '../../..'
import { isExternalURL } from '../../../../helper'

export type TButtonType = 'disabled' | 'blackPrimary' | 'copperPrimary'

export interface IProps {
  text: string
  url?: string
  type?: TButtonType
  linkSx?: SxStyleProp
  linkStyle?: React.CSSProperties
  buttonSx?: SxStyleProp
  customButtonWidth?: (string | number)[] | ''
  buttonExtraPaddingBottom?: string[] | string
  onClick?: () => void
  textStyle?: SxStyleProp
}

const buttonColors = {
  disabled: {
    color: '#FFFFFF',
    backgroundColor: '#D1D1D1',
    fontWeight: 'bold',
    ':hover': {
      cursor: 'default',
    },
  },
  blackPrimary: {
    backgroundColor: '#201A19',
    color: '#FFFFFF',
    ':hover': {
      backgroundColor: '#392E2D',
    },
  },
  copperPrimary: {
    backgroundColor: '#BB8140',
    color: '#FFFFFF',
    ':hover': {
      backgroundColor: '#A05F27',
    },
  },
}

const getButtonColors = (buttonType: TButtonType) => {
  return buttonColors[buttonType]
}

export const CTAButton = ({
  text = '',
  url = '',
  type = 'blackPrimary',
  buttonSx,
  linkSx,
  linkStyle,
  onClick,
  textStyle,
}: IProps) => {
  useEffect(() => {
    console.log(theme)
  }, [])

  return theme !== undefined ? (
    <Link
      sx={{
        width: ['100%', '100%', '155px', '155px'],
        ...(linkStyle as React.CSSProperties),
        ...(linkSx as SxStyleProp),
      }}
      href={url === '' ? undefined : url}
      onClick={onClick}
      target={isExternalURL(url) ? '_blank' : ''}
    >
      <RButton
        sx={{
          padding: '10px 30px',
          borderRadius: 0,
          cursor: 'pointer',
          width: ['100%', '100%', '155px', '155px'],
          ...getButtonColors(type),
          ...(textStyle as SxStyleProp),
          ...buttonSx,
        }}
      >
        <Text tag={'p'} sx={{ ...theme.fontstyles.ctaButton }}>
          {text}
        </Text>
      </RButton>
    </Link>
  ) : (
    <Link></Link>
  )
}
