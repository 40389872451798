import React from 'react'
import { Box, SxStyleProp } from 'rebass'
import './fonts/fonts.css'

// import { theme } from './theme'

import { WithHighlight } from './highlight'

import { Text, ITextProps } from './text'

export interface ITextWithHighlightProps extends ITextProps {
  highlightColor?: string
  centered?: boolean | boolean[]
  highlightSx?: SxStyleProp
  containerSx?: SxStyleProp
  superScript?: string
}

const getJustifyContent = (isCentered?: boolean) => (isCentered ? 'center' : 'flex-start')

/**
 * Defines a primitive text component - Must be extended
 * Primitive Text defines typography and font styles per design system
 * @param props.children
 */
export const TextWithHighlight = ({
  children,
  textRef,
  centered,
  highlightSx,
  containerSx,
  superScript,
  ...textProps
}: ITextWithHighlightProps) => {
  const spaceComponent = <Text {...textProps}>&nbsp;</Text>
  if (typeof children !== 'string') {
    return <Text {...textProps}>{children}</Text>
  }

  const justifyContent =
    typeof centered === 'boolean' || !centered ? getJustifyContent(centered) : centered.map(c => getJustifyContent(c))

  return (
    <Box ref={textRef} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent, ...containerSx }}>
      {children.split(/\n/).map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          {line.split('__').map((text, index, highlightLines) =>
            index % 2 ? (
              <React.Fragment key={index}>
                <WithHighlight sx={highlightSx} color={'transaprent'}>
                  <Text {...textProps} hasPartialBolding>
                    {text}
                  </Text>
                </WithHighlight>
                {text && (index !== highlightLines.length - 1 ? spaceComponent : null)}
              </React.Fragment>
            ) : (
              <React.Fragment key={index}>
                {text.split(' ').map(
                  (word, idx) =>
                    word && (
                      <React.Fragment key={idx}>
                        {idx === text.split(' ').length - 1 ? (
                          <Text {...textProps} hasPartialBolding>
                            {word}
                          </Text>
                        ) : (
                          <Text {...textProps} hasPartialBolding>
                            {word}&nbsp;
                          </Text>
                        )}
                        {idx === text.split(' ').length - 1 && superScript && (
                          <Text
                            tag="body-small"
                            sx={{
                              lineHeight: textProps.tag === 'h1' ? '300%' : '150%',
                              fontSize: textProps.tag === 'h1' ? '75%' : ['65%', '65%', '65%', '85%'],
                              fontWeight: 'bold',
                            }}
                          >
                            {superScript}
                          </Text>
                        )}
                      </React.Fragment>
                    ),
                )}
              </React.Fragment>
            ),
          )}
          <Box sx={{ flexBasis: '100%', height: 0 }} />
        </React.Fragment>
      ))}
    </Box>
  )
}
