import React, { useState, ReactNode, useRef, useLayoutEffect } from 'react'
import { Box } from 'rebass'
import { Waypoint } from 'react-waypoint'
import { theme } from '../styles'
import { NewImage } from './image'
import { Text, TextWithHighlight, TTextTag } from '@northone/ui'

interface IScrollItemProps {
  setIndex: () => void
}

const SCROLL_ITEM_HEIGHT = 350

const ScrollItem = ({ setIndex }: IScrollItemProps) => (
  <Waypoint onEnter={setIndex}>
    <Box sx={{ height: '90vh' }} />
  </Waypoint>
)

interface IScrollTextProps {
  header: string
  title: string
  description: string
  headerTag?: TTextTag
  titleTag?: TTextTag
}

const ScrollText = ({ header, title, description, titleTag = 'h4', headerTag = 'h2' }: IScrollTextProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      color: theme.colors.white,
      fontSize: theme.fontStyles[3].fontSize,
      fontWeight: 'bold',
      textAlign: 'left',
      height: SCROLL_ITEM_HEIGHT,
      width: '45%',
      paddingRight: theme.horizontalPadding,
      justifyContent: 'center',
    }}
  >
    <Box sx={{ paddingY: '20px' }}>
      <TextWithHighlight
        tag={headerTag}
        sx={{
          color: theme.colors.white,
        }}
        // highlightColor={theme.highlightColors.onGold100}
        highlightColor={'transparent'}
      >
        {header}
      </TextWithHighlight>
    </Box>

    <Text
      tag={titleTag}
      sx={{
        color: theme.colors.white,
        paddingBottom: '20px',
      }}
    >
      {title}
    </Text>
    <Text
      tag="body"
      sx={{
        color: theme.colors.white,
        fontWeight: '100',
      }}
    >
      {description}
    </Text>
  </Box>
)

interface IStickyScrollSectionProps {
  topElement: ReactNode
  list: { header: string; title: string; description: string; imageSrc: string; imageDesc: string }[]
  titleTag?: TTextTag
  headerTag?: TTextTag
}

export const StickyScrollSection = ({ topElement, list, headerTag, titleTag }: IStickyScrollSectionProps) => {
  const [currentContentIndex, setCurrentContentIndex] = useState(0)
  const { imageSrc, imageDesc, header, title, description } = list[currentContentIndex]

  const ref = useRef<HTMLDivElement>()
  const [dimensions, setDimensions] = useState<any>()

  useLayoutEffect(() => {
    if (ref && ref.current) {
      setDimensions(ref.current.getBoundingClientRect())
    }
  }, [ref.current])

  const heightOfTopElement = theme.verticalSectionSpacing * 1.7 + (dimensions ? dimensions.height : 0)

  return (
    <>
      <Box sx={{ display: ['flex', 'flex', 'none'] }}>{topElement}</Box>
      <Box sx={{ display: ['none', 'none', 'flex'], flexDirection: 'row' }}>
        <Box
          sx={{
            position: 'sticky',
            top: -heightOfTopElement / 1.5,
            height: heightOfTopElement + SCROLL_ITEM_HEIGHT,
          }}
        >
          <Box ref={ref}> {topElement}</Box>
          <Box
            id="blue-box"
            sx={{
              bg: theme.colors.gold100,
              right: 0,
              height: SCROLL_ITEM_HEIGHT,
              width: '100vw',
              display: 'flex',
              marginTop: theme.verticalSectionSpacing,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '50%',
                paddingRight: [null, null, '10px', '15px', '15px'],
                position: 'relative',
                alignItems: 'center',
              }}
            >
              <NewImage
                sx={{
                  position: 'absolute',
                  width: [null, null, '420px', '440px', '480px'],
                }}
                src={imageSrc}
                alt={imageDesc}
              />
            </Box>
            <ScrollText
              headerTag={headerTag}
              titleTag={titleTag}
              header={header}
              description={description}
              title={title}
            />
          </Box>
        </Box>
        <Box marginTop="150vh">
          {list.map((_item, idx) => (
            <ScrollItem key={idx} setIndex={() => setCurrentContentIndex(idx)} />
          ))}
        </Box>
      </Box>
    </>
  )
}
