import React, { useState } from 'react'
import { Box, Image } from 'rebass'
import { Text } from '@northone/ui'
import { theme } from '../styles'

interface IExpansionPanelProps {
  title: string
  text: JSX.Element
  hasBlueBackground?: boolean
  hasBrownBackground?: boolean
}

export const ExpansionPanel = ({ title, text, hasBlueBackground, hasBrownBackground }: IExpansionPanelProps) => {
  const [isOpened, setIsOpened] = useState(false)
  return (
    <Box sx={{ paddingX: [20, 0] }}>
      <Box
        style={{ cursor: 'pointer' }}
        onClick={() => setIsOpened(!isOpened)}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Text
          sx={{
            color: hasBlueBackground || hasBrownBackground ? theme.colors.white : theme.colors.darkGrey,
            paddingY: '1.5rem',
          }}
          tag="h4"
        >
          {title}
        </Text>
        {isOpened ? (
          <Image
            sx={{ width: 25, display: 'flex', flexShrink: 0, marginLeft: '10px', height: 'auto' }}
            src={
              hasBlueBackground || hasBrownBackground
                ? '/expansion-panel-close-icon.svg'
                : '/expansion-panel-close-icon-grey.svg'
            }
          />
        ) : (
          <Image
            sx={{ width: 25, display: 'flex', flexShrink: 0, marginLeft: 10, height: 'auto' }}
            src={
              hasBlueBackground || hasBrownBackground
                ? '/expansion-panel-open-icon.svg'
                : '/expansion-panel-open-icon-grey.svg'
            }
            alt="no Image"
          />
        )}
      </Box>
      {isOpened && (
        <Text
          tag={['body', 'body', 'body', 'body-large']}
          sx={{
            transition: '1s linear ease-in',
            color: hasBlueBackground || hasBrownBackground ? theme.colors.white : theme.colors.darkGrey,
            paddingBottom: '0.5rem',
            width: ['auto', '80%'],
          }}
        >
          {text}
        </Text>
      )}
      <Box
        style={{
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: hasBlueBackground || hasBrownBackground ? theme.colors.white : theme.colors.disabledGrey,
        }}
      />
    </Box>
  )
}
