import React from 'react'
import { Box, SxStyleProp } from 'rebass'
import { NewImage } from './image'
import { theme } from '../styles'
import { TButtonType, Button } from '.'
import { Text, TTextTag } from '@northone/ui'

interface IPillProps {
  backgroundColor: string
  textColor?: string
  subtitleColor?: string
  imageSrc: string
  mobileImageSrc?: string
  imageDesc?: string
  title?: string | null
  subtitle?: string | null
  reverse?: boolean
  button?: {
    text: string
    url: string
    type?: TButtonType
    onClick?: () => void
  } | null
  sx?: SxStyleProp
  centerTextBlock?: boolean
  customImageHeight?: string | string[]
  customMobileImageHeight?: string | string[]
  customImageMarginLeft?: string | string[]
  customSubtitleTag?: TTextTag
  customSubtitleFontWeight?: string
  customTextContainerWidth?: string | string[]
  customParagraphMaxWidth?: string | string[]
  subtitleSx?: SxStyleProp
  titleTag?: TTextTag
}

export const Pill = ({
  backgroundColor,
  title = '',
  subtitle,
  imageSrc,
  mobileImageSrc,
  imageDesc,
  textColor = theme.colors.white,
  subtitleColor = theme.colors.white,
  reverse = false,
  button,
  centerTextBlock = false,
  customImageHeight,
  customMobileImageHeight,
  customImageMarginLeft,
  customSubtitleTag,
  customSubtitleFontWeight,
  customTextContainerWidth,
  customParagraphMaxWidth,
  sx,
  subtitleSx,
  titleTag,
}: IPillProps) => (
  <Box
    sx={{
      borderRadius: '30px',
      bg: backgroundColor,
      display: 'flex',
      flexDirection: ['column', 'column', reverse ? 'row-reverse' : 'row'],
      height: ['auto', 'auto', '12rem'],
      alignItems: 'center',
      ...sx,
    }}
  >
    <Box
      sx={{
        width: ['100%', '100%', '50%'],
        position: 'relative',
        height: '100%',
        display: 'flex',
        justifyContent: ['center', 'center', reverse ? 'flex-end' : 'flex-start'],
        marginLeft: customImageMarginLeft ? customImageMarginLeft : 0,
        flexShrink: 0,
      }}
    >
      <NewImage
        mobileSx={{
          marginTop: '-2rem',
          height: customMobileImageHeight ? customMobileImageHeight : '150px',
          width: 'auto',
        }}
        sx={{
          justifyContent: 'flex-start',
          bottom: 0,
          height: customImageHeight ? customImageHeight : [null, null, '105%', '110%', '110%'],
          width: 'auto',
          position: 'absolute',
          ...(reverse ? { right: 0 } : { left: 0 }),
        }}
        mobileSrc={mobileImageSrc}
        src={imageSrc}
        alt={imageDesc}
      />
    </Box>
    <Box
      sx={{
        width: customTextContainerWidth ? customTextContainerWidth : ['80%', '80%', '50%', '50%', '45%'],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: centerTextBlock ? 'center' : ['center', 'center', 'flex-start'],
        paddingX: [0, 0, '2rem', '2rem', 0],
        paddingY: ['2rem', '2rem', 0],
      }}
    >
      {title ? (
        <Text
          tag={titleTag ? titleTag : 'h4'}
          sx={{ color: textColor, marginBottom: '5px', textAlign: ['center', 'center', 'left'] }}
        >
          {title}
        </Text>
      ) : null}
      {subtitle ? (
        <Text
          tag={customSubtitleTag ? customSubtitleTag : 'body'}
          sx={{
            color: subtitleColor,
            fontWeight: customSubtitleFontWeight ? customSubtitleFontWeight : undefined,
            textAlign: centerTextBlock ? 'center' : ['center', 'center', 'left'],
            maxWidth: customParagraphMaxWidth ? customParagraphMaxWidth : [''],
            ...subtitleSx,
          }}
        >
          {subtitle}
        </Text>
      ) : null}
      {button ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '-10px',
            justifyContent: centerTextBlock ? 'center' : ['center', 'center', 'start'],
          }}
        >
          <Button text={button.text} url={button.url} type={button.type} />
        </Box>
      ) : null}
    </Box>
  </Box>
)
