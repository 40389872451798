import React from 'react'
import {
  generateWebpageSchema,
  generatePersonSchema,
  generateOrganizationSchema,
  generateContactPointSchema,
  generateAboutPageSchema,
  generateContactPageSchema,
  generateFAQPageSchema,
} from './schemas'
import { TBreadcrumb } from '../pages'

interface IWebPageStructuredDataProps {
  name: string
  description: string
  path: string
  breadcrumbs: TBreadcrumb[]
}
interface IPersonStructuredDataProps {
  person: 'Eytan' | 'Justin' | 'Blake'
}

type SchemaType = 'Organization' | 'ContactPoint' | 'AboutPage' | 'ContactPage' | 'FAQPage'
interface IStructuredDataProps {
  schemaTypes: SchemaType[]
}

const SCRIPT_TYPE = 'application/ld+json'

const generateScriptTagWithKey = (schemaType: SchemaType, key: number): JSX.Element => {
  let schema
  switch (schemaType) {
    case 'Organization':
      schema = generateOrganizationSchema()
      break
    case 'ContactPoint':
      schema = generateContactPointSchema()
      break
    case 'AboutPage':
      schema = generateAboutPageSchema()
      break
    case 'ContactPage':
      schema = generateContactPageSchema()
      break
    case 'FAQPage':
      schema = generateFAQPageSchema()
      break
  }
  return <script type={SCRIPT_TYPE} dangerouslySetInnerHTML={schema} key={key} />
}

export const WebPageStructuredData = ({ name, description, path, breadcrumbs }: IWebPageStructuredDataProps) => (
  <script type={SCRIPT_TYPE} dangerouslySetInnerHTML={generateWebpageSchema(name, description, path, breadcrumbs)} />
)

export const PersonStructuredData = ({ person }: IPersonStructuredDataProps) => (
  <script type={SCRIPT_TYPE} dangerouslySetInnerHTML={generatePersonSchema(person)} />
)

export const StructuredData = ({ schemaTypes }: IStructuredDataProps) => (
  <>{schemaTypes.map((schemaType, index) => generateScriptTagWithKey(schemaType, index))}</>
)
