import { theme as n1Theme } from '@northone/ui'

export const theme = {
  breakpoints: ['426px', '833px', '1024px', '1200px', '1440px', '1800px'],

  contentMaximumWidth: ['100%', '100%', '100%', '100%', '100%', 1000],

  verticalSectionSpacing: 100,
  verticalPadding: 300,

  halfVerticalSectionSpacing: 50,

  verticalElementSpacing: 30,

  noVerticalElementSpacing: 0,

  horizontalPadding: ['5vw', '10vw', '9vw', '12vw', '15vw'],

  colors: {
    black: '#000000',
    orange: '#F2994A',
    blue: '#009BFF',
    lightBlue: '#F2FAFF',
    mediumBlue: '#DAF0FF',
    greyBlue: '#E1EAF1',
    disabledGrey: '#D1D1D1',
    lightGrey: '#F7F7F7',
    mediumGrey: '#777676',
    darkGrey: '#818D95',
    red: '#FF0000',
    white: '#FFFFFF',
    accent: '#E8974F',
    green: '#64CACB',
    lightGreen: '#E0F7F7',
    mediumGreen: '#DAFFF3',
    teal: '#25C6C8',
    lightGrey2: '#F5F8FA',
    serenade: '#FFF7EA',
    solitaire: '#FEF7E5',
    ...n1Theme.colors,
    goldDark: '#C99B40',

    goldPrimary: '#D9B560',

    goldTint1: '#EDCE81',

    goldTint2: '#F2DEAB',

    goldTint3: '#F6EBCD',

    goldTint4: '#FFF7E7',

    copperDark: '#A05F27',

    copperPrimary: '#BB8140',

    copperTint1: '#D3A065',

    copperTint2: '#E7C9A9',

    copperTint3: '#F0DFCB',

    copperTint4: '#F8F0E6',

    blackRich: '#160D0B',

    blackPrimary: '#201A19',

    blackTint1: '#756C6A',

    blackTint2: '#BDB6B4',

    blackTint3: '#EEEAE9',

    libertyGreenPrimary: '#6E947B',

    libertyGreenTint1: '#94AF9D',

    libertyGreenTint2: '#BDCFC3',

    libertyGreenTint3: '#DCE4DE',

    libertyGreenTint4: '#EFF2F0',

    canyonPrimary: '#BF7F6D',

    canyonTint1: '#D09F92',

    canyonTint2: '#E7C8C0',

    canyonTint3: '#F1E0DB',

    canyonTint4: '#FAF2F0',

    brickPrimary: '#7E3A1B',

    brickTint1: '#A47460',

    brickTint2: '#CDAEA3',

    brickTint3: '#E5D6D0',

    brickTint4: '#F4EEEB',

    hoverStateBlackCTAs: '#392E2D',
    hoverStateCopperCTAs: '#A05F27',
    hoverStateLogin: '#F6EBCD',
    hoverStateLearMoreHeader: '#FFF7E7',
  },

  fonts: {
    body: 'Cerebri Sans',
    heading: 'Cerebri Sans',
    monospace: 'Cerebri Sans',
    grtskPeta: 'GrtskPeta',
    grtskPetaLight: 'GrtskPetaLight',
    grtskPetaSemiBold: 'GrtskPetaSemi',
    grtskExa: 'GrtskExa',
    grtskExaSemiBold: 'GrtskExaSemi',
    plantinProLight: 'Plantin-MT-Pro-Light',
    plantinProSemiBold: 'Plantin-MT-Pro-Semi-Bold',
  },

  highlightColors: {
    onWhite: '#EAE7E1',
    onGold10: '#EAE7E1',
    onGold30: '#DDD9D0',
    onGold80: '#CFC9BB',
    onBlue10: '#D6E1E9',
    onGold100: '#FFFFFF20',
    onBlue100: '#FFFFFF20',
  },

  imageHeights: ['80%', '400px', '420px', '450px', '490px'],

  buttonHeight: 42,

  buttonWidth: ['80vw', 200],

  navbar: {
    height: [44, 44, 88, 88],
    mainPageOffset: [107, 107, 160, 135],
    breadcrumbPageOffset: [0, 0, 30],
    logoWidth: [100, 150, 160, 180],
  },

  navBarButtonHeight: '42px',

  // Desktop
  fontStyles: [
    { lineHeight: '50px', fontSize: 48, letterSpacing: '-0.16px', fontFamily: 'plantin-MT-Pro-SemiBold' }, // h1
    { lineHeight: '40px', fontSize: 36, letterSpacing: '-0.16px', fontFamily: 'plantin-MT-Pro-SemiBold' }, // h2
    { lineHeight: '24px', fontSize: 28, letterSpacing: '-0.16px', fontFamily: 'plantin-MT-Pro-SemiBold' }, // h3
    { lineHeight: '24px', fontSize: 20, letterSpacing: '-0.16px', fontFamily: 'plantin-MT-Pro-SemiBold' }, // h4
    { lineHeight: '16px', fontSize: 14, letterSpacing: '-0.16px', fontFamily: 'plantin-MT-Pro-SemiBold' }, // h5
    { lineHeight: '22px', fontSize: 16, letterSpacing: '-0.16px', fontFamily: 'grtskPetaLight' }, // p
    { lineHeight: '28px', fontSize: 20 }, // body xLarge
    { lineHeight: '30px', fontSize: 22 }, // body xLarge
  ],

  fontstyles: {
    h1: { lineHeight: ['50px'], fontSize: ['48px'], letterSpacing: ['-0.16px'], fontFamily: 'plantin-MT-Pro-SemiBold' }, // h1
    h2: { lineHeight: ['40px'], fontSize: ['36px'], letterSpacing: ['-0.16px'], fontFamily: 'plantin-MT-Pro-SemiBold' }, // h2
    h3: { lineHeight: ['24px'], fontSize: ['28px'], letterSpacing: ['-0.16px'], fontFamily: 'plantin-MT-Pro-SemiBold' }, // h3
    h4: { lineHeight: ['24px'], fontSize: ['20px'], letterSpacing: ['-0.16px'], fontFamily: 'plantin-MT-Pro-SemiBold' }, // h4
    h5: { lineHeight: ['16px'], fontSize: ['14px'], letterSpacing: ['-0.16px'], fontFamily: 'plantin-MT-Pro-SemiBold' }, // h5
    p: { lineHeight: ['22px'], fontSize: ['16px'], letterSpacing: ['-0.16px'], fontFamily: 'grtskPetaLight' }, // p
    menu: {
      secondary: {
        lineHeight: ['17px', '22px'],
        fontSize: ['12px', '16px'],
        fontFamily: 'grtskPeta',
      },
      tertiary: {
        lineHeight: ['24px', '24px', '24px', '22px', '22px'],
        fontSize: ['12px', '12px', '12px', '16px', '16px'],
        fontFamily: 'grtskPeta',
      },
    }, // menu
    eyeBrowText: {
      lineHeight: ['14px'],
      fontSize: ['12px'],
      letterSpacing: ['0.1em'],
      fontFamily: 'grtskExaSemiBold',
      color: '#A05F27',
    }, // eye-brow-text
    heroP: { lineHeight: ['30px'], fontSize: ['22px'], letterSpacing: ['-0.16px'], fontFamily: 'grtskPetaLight' }, // hero-p
    ctaButton: {
      color: '#FFFFFF',
      fontSize: ['16px'],
      fontFamily: 'grtskPetaSemiBold',
      lineHeight: ['22px'],
      letterSpacing: ['-0.16px'],
    }, // cta-button
    legalDisclaimers: {
      color: '#201A19',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: ['12px'],
      fontFamily: ['grtskExa', 'grtskExa'],
      lineHeight: ['14px'],
      letterSpacing: ['-0.01em'],
    }, // legal-disclaimer
    bodyLarge: { lineHeight: '28px', fontSize: 20 }, // body large
    bodyXlargearge: { lineHeight: '30px', fontSize: 22 }, // body x-large
  },
  v2: {
    modulePadding: {
      XL: '128px',
      L: '96px',
      MD: '64px',
      SM: '32px',
      XS: '16px',
      NONE: '0',
    },
    moduleMargin: {
      XL: '128px',
      L: '96px',
      MD: '64px',
      SM: '32px',
      XS: '16px',
      NONE: '0',
    },
    colors: {
      orange: '#F2994A',
      blue: '#009BFF',
      lightBlue: '#F2FAFF',
      mediumBlue: '#DAF0FF',
      greyBlue: '#E1EAF1',
      disabledGrey: '#D1D1D1',
      lightGrey: '#F7F7F7',
      mediumGrey: '#777676',
      darkGrey: '#818D95',
      red: '#FF0000',
      white: '#FFFFFF',
      accent: '#E8974F',
      green: '#64CACB',
      lightGreen: '#E0F7F7',
      mediumGreen: '#DAFFF3',
      teal: '#25C6C8',
      lightGrey2: '#F5F8FA',
      serenade: '#FFF7EA',
      solitaire: '#FEF7E5',
      goldDark: '#C99B40',
      goldPrimary: '#D9B560',
      goldTint1: '#EDCE81',
      goldTint2: '#F2DEAB',
      goldTint3: '#F6EBCD',
      goldTint4: '#FFF7E7',
      copperDark: '#A05F27',
      copperPrimary: '#BB8140',
      copperTint1: '#D3A065',
      copperTint2: '#E7C9A9',
      copperTint3: '#F0DFCB',
      copperTint4: '#F8F0E6',
      blackRich: '#160D0B',
      blackPrimary: '#201A19',
      blackTint1: '#756C6A',
      blackTint2: '#BDB6B4',
      blackTint3: '#EEEAE9',
      libertyGreenPrimary: '#6E947B',
      libertyGreenTint1: '#94AF9D',
      libertyGreenTint2: '#BDCFC3',
      libertyGreenTint3: '#DCE4DE',
      libertyGreenTint4: '#EFF2F0',
      canyonPrimary: '#BF7F6D',
      canyonTint1: '#D09F92',
      canyonTint2: '#E7C8C0',
      canyonTint3: '#F1E0DB',
      canyonTint4: '#FAF2F0',
      brickPrimary: '#7E3A1B',
      brickTint1: '#A47460',
      brickTint2: '#CDAEA3',
      brickTint3: '#E5D6D0',
      brickTint4: '#F4EEEB',
    },
    fontStyles: {
      h1: {
        fontSize: ['36px', '36px', '48px', '48px'],
        lineHeight: ['40px', '40px', '50px', '50px'],
        letterSpacing: '-0.16px', // OR IS IT ACTUALLY? Mixed results in the screens -0.01em;
        fontWeight: 300,
        fontFamily: 'Plantin-MT-Pro-Semi-Bold',
        textTransform: 'none',
      },
      h2: {
        fontSize: ['28px', '28px', '36px', '36px'],
        lineHeight: ['32px', '32px', '40px', '40px'],
        letterSpacing: '-0.16px',
        fontWeight: 300,
        fontFamily: 'Plantin-MT-Pro-Semi-Bold',
        textTransform: 'none',
      },
      h3: {
        fontSize: '28px',
        lineHeight: '32px',
        fontWeight: 500,
        fontFamily: 'GrtskPeta',
        letterSpacing: '-0.16px',
        textTransform: 'none',
      },
      h3Serif: {
        fontSize: '28px',
        lineHeight: '32px',
        fontWeight: 500,
        fontFamily: 'Plantin-MT-Pro-Semi-Bold',
        letterSpacing: '-0.16px',
        textTransform: 'none',
      },
      h4: {
        fontSize: '20px',
        lineHeight: '26px',
        letterSpacing: '-0.16px',
        fontWeight: 500,
        fontFamily: 'Plantin-MT-Pro-Semi-Bold',
        textTransform: 'none',
      },
      h5: {
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '-0.16px',
        fontWeight: 500,
        fontFamily: 'Plantin-MT-Pro-Semi-Bold',
        textTransform: 'none',
      },
      bodyXXlarge: {
        fontSize: ['22px', '22px', '32px', '32px'],
        lineHeight: ['30px', '30px', '40px', '40px'],
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPeta',
      },
      bodyXXlargeLight: {
        fontSize: ['22px', '22px', '32px', '32px'],
        lineHeight: ['30px', '30px', '40px', '40px'],
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPetaLight',
      },
      bodyXlarge: {
        fontSize: '22px',
        lineHeight: '30px',
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPeta',
      },
      bodyXlargeBold: {
        fontSize: ['22px', '22px'],
        lineHeight: ['30px', '30px'],
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPetaSemi',
        fontWeight: 600,
      },
      bodyXlargeLight: {
        fontSize: ['22px', '22px'],
        lineHeight: ['30px', '30px'],
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPetaLight',
      },
      bodyXlargeSemibold: {
        fontSize: ['22px', '22px'],
        lineHeight: ['30px', '30px'],
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPeta',
        fontWeight: 600,
      },
      bodyLarge: {
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPeta',
      },
      bodyLargeBold: {
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPetaSemi',
        fontWeight: 600,
      },
      bodyLargeLight: {
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPetaLight',
      },
      bodyMedium: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPetaLight',
      },
      bodyMediumBold: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPetaSemi',
        fontWeight: 600,
      },
      bodySmall: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPeta',
      },
      bodySmallBold: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPetaSemi',
        fontWeight: 600,
      },
      bodyXsmall: {
        fontSize: '10px',
        lineHeight: '14px',
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPeta',
      },
      numeralsHeader: {
        fontFamily: 'GrtskPeta',
        fontSize: '32px',
        lineHeight: '36px',
        letterSpacing: '-0.16px',
      },
      menuSecondary: {
        // Specialized Font Styles
        lineHeight: ['17px', '22px'],
        fontSize: ['12px', '16px'],
        fontFamily: 'grtskPeta',
      },
      menuTertiary: {
        lineHeight: ['24px', '24px', '24px', '22px', '22px'],
        fontSize: ['12px', '12px', '12px', '16px', '16px'],
        fontFamily: 'grtskPeta',
      },
      eyebrow: {
        lineHeight: ['14px'],
        fontSize: ['12px'],
        letterSpacing: ['0.1em'],
        fontFamily: 'GrtskExaSemi',
      }, // eye-brow-text
      heroP: {
        lineHeight: ['30px'],
        fontSize: ['22px'],
        letterSpacing: ['-0.16px'],
        fontFamily: 'grtskPetaLight',
      }, // hero-p
      smallPrint: {
        fontSize: '10px',
        fontWeight: 300,
        fontFamily: 'GrtskPetaSemi',
      },
      inlineLabel: {
        fontSize: '10px',
        fontWeight: 300,
        fontFamily: 'GrtskExaSemiBold',
      },
      ctaButton: {
        fontSize: ['16px'],
        fontFamily: 'grtskPetaSemiBold',
        lineHeight: ['22px'],
        letterSpacing: ['-0.16px'],
      }, // cta-button
      legalDisclaimers: {
        color: '#201A19',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: ['10px'],
        fontFamily: ['grtskExa', 'grtskExa'],
        lineHeight: ['14px'],
        letterSpacing: ['-0.01em'],
      }, // legal-disclaimer
      textBlockList: {
        fontSize: ['14px', '16px'],
        lineHeight: ['20px', '22px'],
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPetaLight',
      },
      textBlockListBold: {
        fontWeight: 600,
        fontSize: ['14px', '16px'],
        lineHeight: ['20px', '22px'],
        letterSpacing: '-0.16px',
        fontFamily: 'GrtskPeta',
      },
    },
    buttonStyles: {
      cta: {
        blackPrimary: {
          backgroundColor: '#201A19',
          color: '#FFFFFF',
          ':hover': {
            backgroundColor: '#392E2D',
            cursor: 'pointer',
          },
        },
        copperPrimary: {
          backgroundColor: '#BB8140',
          color: '#FFFFFF',
          ':hover': {
            backgroundColor: '#A05F27',
            cursor: 'pointer',
          },
        },
        disabled: {
          color: '#FFFFFF',
          backgroundColor: '#D1D1D1',
          fontWeight: 'bold',
          ':hover': {
            cursor: 'default',
          },
        },
        whitePrimary: {
          color: '#201A19',
          backgroundColor: '#FFFFFF',
          fontWeight: 'bold',
          ':hover': {
            backgroundColor: '#FFFFFF',
            cursor: 'pointer',
          },
        },
      },
    },
    fonts: {
      body: 'CerebriSans',
      heading: 'CerebriSans',
      monospace: 'CerebriSans',
      grtskPeta: 'GrtskPeta',
      grtskPetaLight: 'GrtskPetaLight',
      grtskPetaSemiBold: 'GrtskPetaSemi',
      grtskExa: 'GrtskExa',
      grtskExaSemiBold: 'GrtskExaSemi',
      plantinProLight: 'Plantin-MT-Pro-Light',
      plantinProSemiBold: 'Plantin-MT-Pro-Semi-Bold',
    },
    imageHeights: ['80%', '400px', '420px', '450px', '490px'],
    verticalElementSpacing: 30,
    utils: {
      flexColumn: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    grids: {
      fiveColumnGrid: {
        display: 'grid',
        '@media (min-width:300px)': {
          gridTemplateColumns: 'repeat(2,1fr)',
        },

        '@media (min-width:768px)': {
          gridTemplateColumns: 'repeat(5, 1fr)',
        },
      },
    },
  },
}
