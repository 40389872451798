import { Box, BoxProps, SxStyleProp } from 'rebass'
import React, { ReactNode } from 'react'
import { theme } from '../styles'

interface IBaseLayoutProps {
  imageComponent?: ReactNode
  bodyComponent?: ReactNode
  reverse?: boolean
  reverseMobile?: boolean
  color?: string
  backgroundFillPercentage?: number
  fullScreen?: boolean
  centeredComponent?: ReactNode
  centeredComponentPosition?: 'top' | 'bottom'
  containerSx?: SxStyleProp
  boxSx?: SxStyleProp
  bodyComponentWidth?: string[] | string
  marginTop?: string | number | (string | number)[]
  alignComponents?: 'flex-start' | 'flex-end' | 'center' | ('flex-start' | 'flex-end' | 'center')[]
  imageComponentWidth?: string | string[]
  footer?: ReactNode
  horizontalPadding?: string
  disclaimer?: string
}

interface IStandardPaddingProps extends BoxProps {
  fullScreen?: boolean
  horizontalPadding?: string
}

interface IBackgroundColorProps {
  color: string
  backgroundFillPercentage?: number
}

export const StandardPadding = (props: IStandardPaddingProps) => (
  <Box
    height={[null, null, props.fullScreen ? '100vh' : null]}
    width={'100vw'}
    paddingX={props.horizontalPadding ? props.horizontalPadding : theme.horizontalPadding}
    {...props}
    marginTop={
      props.fullScreen ? ['50px', '50px', 0] : props.marginTop ? props.marginTop : theme.verticalSectionSpacing
    }
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      ...props.sx,
    }}
  >
    {props.children}
  </Box>
)

export const BackgroundColor = ({ color, backgroundFillPercentage = 100 }: IBackgroundColorProps) => (
  <Box
    sx={{
      bg: color,
      position: 'absolute',
      height: ['100%', '100%', `${backgroundFillPercentage}%`],
      width: '100vw',
      left: 0,
      zIndex: -1,
      bottom: [0, 0, 'auto'],
      alignSelf: 'center',
    }}
  />
)

export const BaseLayout = ({
  bodyComponent,
  imageComponent,
  reverse,
  reverseMobile,
  color = '',
  backgroundFillPercentage,
  fullScreen,
  centeredComponent,
  centeredComponentPosition = 'top',
  containerSx,
  boxSx,
  bodyComponentWidth,
  marginTop = '',
  alignComponents = 'center',
  imageComponentWidth = 'auto',
  footer = false,
  horizontalPadding,
  disclaimer,
}: IBaseLayoutProps) => (
  <StandardPadding
    horizontalPadding={horizontalPadding}
    fullScreen={fullScreen}
    marginTop={marginTop}
    sx={{
      position: 'relative',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: centeredComponentPosition === 'top' ? 'column' : 'column-reverse',
      paddingY: color
        ? [
            theme.verticalSectionSpacing,
            theme.verticalSectionSpacing,
            theme.verticalSectionSpacing,
            backgroundFillPercentage ? 0 : theme.verticalSectionSpacing,
          ]
        : 0,
      ...containerSx,
    }}
  >
    <BackgroundColor color={color} backgroundFillPercentage={backgroundFillPercentage} />
    <Box>{centeredComponent}</Box>
    <Box
      sx={{
        width: theme.contentMaximumWidth,
        display: 'flex',
        flexDirection: reverse
          ? ['column-reverse', 'column-reverse', 'row-reverse']
          : reverseMobile
          ? ['column', 'column', 'row']
          : ['column-reverse', 'column-reverse', 'row'],
        justifyContent: ['center', 'center', 'space-between'],
        alignItems: alignComponents,
        position: 'relative',
        ...boxSx,
      }}
    >
      <Box
        sx={{
          width: bodyComponentWidth || ['100%', '100%', '300px', '330px', '400px'],
          height: [undefined, undefined, '100%', '100%', '100%'],
          display: 'flex',
          alignItems: 'center',
          justifyContent: reverse ? 'center' : ['center', 'center', 'flex-start'],
        }}
      >
        {bodyComponent}
      </Box>
      <Box
        sx={{
          width: imageComponentWidth,
          maxWidth: '100vw',
          height: ['80%', '80%', undefined],
          display: 'flex',
          alignItems: 'center',
          justifyContent: reverse ? ['center', 'center', 'flex-start'] : ['center', 'center', 'flex-end'],
          marginBottom: [
            reverseMobile ? 0 : theme.verticalElementSpacing,
            reverseMobile ? 0 : theme.verticalElementSpacing,
            0,
          ],
        }}
      >
        {imageComponent}
      </Box>
    </Box>
    {disclaimer && (
      <Box
        sx={{
          padding: 20,
          paddingX: [20, 50, 50],
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '14px',
          color: color === theme.colors.gold80 ? theme.colors.ui7 : theme.colors.ui1,
          textAlign: 'center',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        {disclaimer}
      </Box>
    )}
    {footer ? footer : null}
  </StandardPadding>
)
