import React from 'react'
import { Image as RImage, ImageProps as RImageProps, Box, SxStyleProp } from 'rebass'

interface INewImageProps extends RImageProps {
  mobileSrc?: string
  mobileSx?: SxStyleProp
  customWidth?: string[] | string
}

export const NewImage = (props: INewImageProps) => {
  const { mobileSrc, sx, mobileSx, src, customWidth } = props
  return mobileSrc ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <RImage
        {...props}
        src={mobileSrc || src}
        sx={{
          display: ['flex', 'flex', 'none'],
          width: ['80%', '400px', '420px', '450px', '480px'],
          height: '80',
          objectFit: 'contain',
          alignSelf: 'center',

          ...(mobileSx ? mobileSx : sx),
        }}
      />
      <RImage
        {...props}
        sx={{
          alignSelf: 'center',
          display: ['none', 'none', 'flex'],
          height: '80',
          width: customWidth || ['80%', '400px', '420px', '450px', '480px'],
          objectFit: 'contain',
          ...sx,
        }}
      />
    </Box>
  ) : (
    <RImage
      sx={{
        width: customWidth || ['80%', '400px', '420px', '450px', '480px'],
        alignSelf: 'center',
        objectFit: 'contain',
        height: '80',
        ...sx,
      }}
      {...props}
    />
  )
}
