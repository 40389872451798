/**
 * Fire Segment Analytics event for clicking to join.northone.com
 * properties are set to match Goal in Google Analytics
 */
export const fireWebsiteSignupEvent = (): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  window.analytics.track('Click', {
    category: 'Website - Signup',
    action: 'Click',
  })

  console.log('fireWebsiteSignupEvent: Website - Signup')
}
