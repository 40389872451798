import React, { ReactNode, useState } from 'react'
import { Box } from 'rebass'
import { Waypoint } from 'react-waypoint'

interface ILazyLoadBlockProps {
  children: ReactNode
}

export const LazyLoadBlock = ({ children }: ILazyLoadBlockProps) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <Waypoint
      onEnter={() => {
        if (!isVisible) {
          setIsVisible(true)
        }
      }}
      bottomOffset={'-300px'}
    >
      <Box>{isVisible ? children : <Box sx={{ height: 500, width: '100%' }} />}</Box>
    </Waypoint>
  )
}
