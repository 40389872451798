/* tslint:disable comment-format */
import { TBreadcrumb } from '../pages'
import { getBreadcrumbPath } from '../../shared'
import { PAGES } from '..'

type RecordMap = { __html: string }

const CONTEXT = 'http://schema.org'
const NORTHONE_URL = 'https://www.northone.com'
const NORTHONE_LOGO = `${NORTHONE_URL}/favicon.png`

const generateBreadcrumbList = (breadcrumbs: TBreadcrumb[]): RecordMap & any => {
  return {
    '@context': CONTEXT,
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, i) => {
      const lastItem = i === breadcrumbs.length - 1
      return {
        '@type': 'ListItem',
        position: i + 1,
        name: breadcrumb,
        item: lastItem ? '' : `${NORTHONE_URL}${getBreadcrumbPath(breadcrumb)}`,
      }
    }),
  }
}

export const generateWebpageSchema = (
  name: string,
  description: string,
  path: string,
  breadcrumbs: TBreadcrumb[],
): { __html: string } => {
  return {
    __html: JSON.stringify({
      '@context': CONTEXT,
      '@type': 'WebPage',
      name,
      description,
      url: `${NORTHONE_URL}${path}`,
      breadcrumb: generateBreadcrumbList(breadcrumbs),
    }),
  }
}

type TPerson = 'Eytan' | 'Justin' | 'Blake'
export const generatePersonSchema = (person: TPerson): RecordMap => {
  switch (person) {
    case 'Eytan':
      return {
        __html: JSON.stringify({
          '@context': CONTEXT,
          '@type': 'Person',
          givenName: 'Eytan',
          familyName: 'Bensoussan',
          jobTitle: 'Co-founder and CEO',
          description: 'Eytan Bensoussan is the Co-Founder and CEO of NorthOne.',
          image: `${NORTHONE_URL}/static/images/team-images/team-eytan.png`,
          sameAs: 'https://www.linkedin.com/in/eytanbensoussan/',
          mainEntityOfPage: 'https://www.linkedin.com/in/eytanbensoussan/',
        }),
      }
    case 'Justin':
      return {
        __html: JSON.stringify({
          '@context': CONTEXT,
          '@type': 'Person',
          givenName: 'Justin',
          familyName: 'Adler',
          jobTitle: 'Co-Founder and COO',
          description: 'Justin Adler is the Co-Founder and COO of NorthOne.',
          image: 'https://www.northone.com/static/images/team-images/team-justin.png',
          sameAs: ['https://www.linkedin.com/in/thejustinadler/', 'https://twitter.com/thejustinadler'],
          mainEntityOfPage: 'https://www.linkedin.com/in/thejustinadler/',
        }),
      }
    case 'Blake':
      return {
        __html: JSON.stringify({
          '@context': CONTEXT,
          '@type': 'Person',
          givenName: 'Blake',
          familyName: 'Edwards',
          jobTitle: 'VP of Engineering',
          description: 'Blake Edwards is the VP of Engineering of NorthOne.',
          image: `${NORTHONE_URL}/static/images/team-images/team-blake.png`,
          sameAs: ['https://www.linkedin.com/in/electblake/', 'https://twitter.com/electblake'],
          mainEntityOfPage: 'https://www.linkedin.com/in/electblake/',
        }),
      }
  }
}

export const generateOrganizationSchema = (): RecordMap => {
  return {
    __html: JSON.stringify({
      '@context': CONTEXT,
      '@type': 'Organization',
      name: 'NorthOne',
      url: NORTHONE_URL,
      logo: NORTHONE_LOGO,
      founders: [
        {
          '@type': 'Person',
          name: 'Eytan Bensoussan',
        },
        {
          '@type': 'Person',
          name: 'Justin Adler',
        },
      ],
      location: 'USA',
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        email: 'support@northone.com',
      },
      areaServed: 'USA',
      foundingDate: '2016',
      sameAs: [
        'https://www.facebook.com/northoneapp',
        'https://twitter.com/NorthOneApp',
        'https://www.linkedin.com/company/northone/',
      ],
    }),
  }
}

export const generateAboutPageSchema = (): RecordMap => {
  const ABOUT = PAGES.ABOUT.MAIN
  return {
    __html: JSON.stringify({
      '@context': CONTEXT,
      '@type': 'AboutPage',
      name: 'About | NorthOne',
      description:
        "NorthOne is a business banking platform for small businesses, startups and freelancers. We're on a mission to change the way that small businesses think about banking and finance.",
      breadcrumb: generateBreadcrumbList(ABOUT.BREADCRUMBS),
    }),
  }
}

export const generateContactPageSchema = (): RecordMap => {
  const CONTACT_US = PAGES.CONTACT_US
  return {
    __html: JSON.stringify({
      '@context': CONTEXT,
      '@type': 'ContactPage',
      description:
        'Contact NorthOne during available customer service hours to receive support for all of your banking needs.',
      organization: 'NorthOne',
      logo: NORTHONE_LOGO,
      breadcrumb: generateBreadcrumbList(CONTACT_US.BREADCRUMBS),
    }),
  }
}

export const generateContactPointSchema = (): RecordMap => {
  return {
    __html: JSON.stringify({
      '@context': CONTEXT,
      '@type': 'ContactPoint',
      contactType: 'customer service',
      email: 'support@northone.com',
    }),
  }
}

export const generateFAQPageSchema = (): RecordMap => {
  const FAQ = PAGES.FAQ
  return {
    __html: JSON.stringify({
      '@context': CONTEXT,
      '@type': 'FAQPage',
      breadcrumb: generateBreadcrumbList(FAQ.BREADCRUMBS),
      'main-entity': [
        {
          '@type': 'Question',
          name: 'Is my NorthOne account FDIC insured?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'Yes! All NorthOne accounts are FDIC insured thanks to our partnership with FDIC member, LendingClub Bank, N.A. This means that up to $250,000 of deposits made in your NorthOne account are insured. To find out more about what we’re doing to keep your account protected, visit our security page.',
          },
        },
        {
          '@type': 'Question',
          name: 'What device do I need to use the NorthOne app?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'The NorthOne app works on iPhone 6 or above models, and Android.  We also have a desktop option that lets you sign up for a NorthOne bank account while sitting at your computer  ',
          },
        },
        {
          '@type': 'Question',
          name: 'Will I receive account statements?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              "Absolutely! But don’t wait by the mailbox for them. NorthOne is a completely digital banking app so you’ll get paperless statements through the NorthOne app. Finding your account statements is easy. Follow these steps: Tap the menu icon on the top left of the app  Tap the Business Settings icon  Tap Account Statements  Scroll to find the monthly statement you'd like to export  Tap Export this statement  Tap Download ",
          },
        },
        {
          '@type': 'Question',
          name: 'What should I know about sub-accounts?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'NorthOne sub-accounts make it easier to save for the things that matter most. You can use smart rules to set aside money automatically as a set amount or a percentage. That means that you won’t come up short at payroll time or when tax season comes around. Setting up your new sub-account is easy. Follow these steps: Tap the ‘account’ icon at the bottom of the app  Tap ‘add a sub-account’ Type in the name of your new sub-account 🗂 Start putting your sub-account to good use by transferring money into it ',
          },
        },
        {
          '@type': 'Question',
          name: 'How long do deposits take to clear?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'For the first 30 days after joining NorthOne, there is a 5 business day hold on all deposits into your account. In most cases, after that ACH, ATM, and check deposits will be available in 1-2 days, and wires will be available on the same day.',
          },
        },
        {
          '@type': 'Question',
          name: 'Can I deposit cash into my NorthOne account?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'Yes! You can use your NorthOne Mastercard Debit Card to deposit cash at NYCE or MoneyPass ATM nationwide for free  Cash deposits take the same amount of time to clear as other deposit types and won’t cost you any additional fees. We’ve built an in-app ATM finder to help you easily locate an NYCE or MoneyPass ATM near you.',
          },
        },
        {
          '@type': 'Question',
          name: 'I lost my card or it was stolen. What do I do?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              "Don’t panic! We’re here to help. If you think or know you’ve lost your card, get in contact with NorthOne’s customer support team. To contact support, tap the support button in the app. You’ll have the option to send us an instant chat message, email us, or schedule a phone call for a time that suits you.  If you think your card is lost or stolen, we can lock it to give you more time to look for it.  If you know your card is lost or stolen, report it as lost and we'll send you a new card. New cards are usually delivered within 5-10 business days. You'll have your new shiny card soon! Our support team is available between 8am-1am ET (5am-10pm PT).",
          },
        },
        {
          '@type': 'Question',
          name: 'Can I have more than one NorthOne MasterCard debit card for my company?',
          acceptedAnswer: {
            '@type': 'Answer',
            text:
              'Yes! Every owner of your business can get their own NorthOne MasterCard Debit Card. To get them set up with their very own card, just invite them to the NorthOne account. Inviting someone to join your NorthOne account is easy. Follow these steps: Tap the menu icon on the top left of the app Tap ‘business settings’  Tap invite owners  Select an owner to add to the account  We’ll send them an invite via email ',
          },
        },
      ],
    }),
  }
}
