import React, { useEffect, useState } from 'react'
import { Box, Image } from 'rebass'
import { theme } from '../shared'

const DisclosureBanner: React.FC = () => {
  const visibilityTimeoutMs = 900000
  const disclosureBannerID = 'disclosure-banner'
  const disclosureLocalStorageKey = 'northone.disclosure'

  const [showBanner, setShowBanner] = useState(true)

  const dismissBanner = () => {
    setShowBanner(false)
    localStorage.setItem(disclosureLocalStorageKey, Date.now().toString())
  }

  useEffect(() => {
    if (window.location.pathname === '/') {
      setShowBanner(false)
      return
    }

    const dismissedAt = parseFloat(localStorage.getItem(disclosureLocalStorageKey) || '0')
    if (dismissedAt && Date.now() - dismissedAt > visibilityTimeoutMs) {
      setShowBanner(true)
      localStorage.removeItem(disclosureLocalStorageKey)
    } else if (dismissedAt && Date.now() - dismissedAt < visibilityTimeoutMs) {
      setShowBanner(false)
      return
    }
  })

  return (
    <Box
      id={disclosureBannerID}
      sx={{
        zIndex: 100000,
        display: showBanner ? 'flex' : 'none',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '40px',
        backgroundColor: '#FFF',
        borderTop: '1px solid',
        borderTopColor: '#CFD3DA',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      <Box
        sx={{
          width: '95%',
          height: '100%',
          ...theme.fontstyles.legalDisclaimers,
        }}
      >
        NorthOne is a financial technology company, not a bank. Banking services provided by The Bancorp Bank, N.A.,
        Member FDIC.
      </Box>
      <Image
        id="dismiss-banner-button"
        src="https://northone-assets.s3.us-east-2.amazonaws.com/website/homepage/close-button.png"
        sx={{
          height: '20px',
          cursor: 'pointer',
          paddingLeft: '15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'auto',
        }}
        onClick={dismissBanner}
      />
    </Box>
  )
}

export default DisclosureBanner
