import React, { useEffect, memo } from 'react'
import { useCountUp } from 'react-countup'
import { Box, SxStyleProp } from 'rebass'
import styled from 'styled-components'
import { getDigitsFromNumber } from '../../helper'
import { theme } from '../styles'

type AnimationType = 'fold' | 'unfold'

const CARD_COLOR = theme.colors.gold80
const CARD_GRADIENT = `linear-gradient(180deg, #938666 20%, rgba(155, 155, 155, 0) 100%), ${CARD_COLOR};`
const BORDER_RADIUS = '14px'
const TURN_DURATION = '0.6s'

interface IAnimationWrapperProps {
  direction: AnimationType
}

const paramFromDirection = (options: Record<AnimationType, string>) => ({
  direction,
}: IAnimationWrapperProps): string => options[direction]

const getDirection = ({ direction }: IAnimationWrapperProps): string => direction

const AnimationWrapper = memo(styled(Box)`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  backface-visibility: hidden;
  background: ${paramFromDirection({ unfold: CARD_GRADIENT, fold: CARD_COLOR })};
  border-${paramFromDirection({ unfold: 'bottom', fold: 'top' })}-left-radius: ${BORDER_RADIUS};
  border-${paramFromDirection({ unfold: 'bottom', fold: 'top' })}-right-radius: ${BORDER_RADIUS};
  transform-style: preserve-3d;

  top: ${paramFromDirection({ unfold: '50%', fold: '0%' })};
  align-items: ${paramFromDirection({ unfold: 'flex-start', fold: 'flex-end' })};
  transform-origin: ${paramFromDirection({ unfold: '50% 0%', fold: '50% 100%' })};
  transform: rotateX(${paramFromDirection({ unfold: '180deg', fold: '0deg' })});
  & > span {
    transform: translateY(${paramFromDirection({ unfold: '-50%', fold: '50%' })});
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes unfold {
    0% {
      transform: rotateX(180deg);
      background-color: black;
    }
    100% {
      transform: rotateX(0deg);
    }
  }

  @keyframes fold {
    0% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateX(-180deg);
      background-color: black;
    }
  }

  animation: ${getDirection} ${TURN_DURATION} cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s 1 normal forwards;
`)

interface IAnimateCardProps {
  direction: AnimationType
  digit: number
}

const AnimatedCard: React.FC<IAnimateCardProps> = ({ direction, digit }: IAnimateCardProps) => {
  return (
    <AnimationWrapper key={digit} direction={direction}>
      <span>{digit}</span>
    </AnimationWrapper>
  )
}

interface IStaticCardProps {
  position: 'upper' | 'lower'
  digit: number | string
}

const positionStyles: { upper: SxStyleProp; lower: SxStyleProp } = {
  upper: {
    alignItems: 'flex-end',
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    span: { transform: 'translateY(50%)' },
  },
  lower: {
    alignItems: 'flex-start',
    background: CARD_GRADIENT,
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    span: { transform: 'translateY(-50%)' },
  },
}

const StaticCard: React.FC<IStaticCardProps> = ({ position, digit }: IStaticCardProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        width: '100%',
        height: '50%',
        overflow: 'hidden',
        ...positionStyles[position],
      }}
    >
      <span>{digit}</span>
    </Box>
  )
}

interface IFlipContainerProps {
  children: React.ReactNode
  sx?: SxStyleProp
}

const FlipContainer: React.FC<IFlipContainerProps> = ({ children, sx }: IFlipContainerProps) => (
  <Box
    sx={{
      display: 'block',
      position: 'relative',
      marginLeft: '2px',
      ':nth-last-of-type(3n)': {
        marginLeft: '14px',
      },
      width: '48px',
      height: '73px',
      fontSize: '50px',
      color: 'white',
      fontWeight: 'bold',
      perspectiveOrigin: '50% 50%',
      perspective: '300px',
      backgroundColor: CARD_COLOR,
      borderRadius: BORDER_RADIUS,
      boxShadow: '0px 10px 10px -10px grey',
      ...sx,
    }}
  >
    {children}
  </Box>
)

interface IFlipPanelProps {
  digit: number
  sx?: SxStyleProp
}

const FlipNumberPanel: React.FC<IFlipPanelProps> = ({ digit, sx }: IFlipPanelProps) => {
  const prevDigit = digit === 0 ? 9 : digit - 1
  const nextDigit = digit
  return (
    <FlipContainer sx={sx}>
      <StaticCard position="upper" digit={nextDigit} />
      <StaticCard position="lower" digit={prevDigit} />
      <AnimatedCard direction="fold" digit={prevDigit} />
      <AnimatedCard direction="unfold" digit={nextDigit} />
    </FlipContainer>
  )
}

interface IProps extends ReactCountUp.Props {
  prefix?: string
  sx?: SxStyleProp
  panelSx?: SxStyleProp
  end: number
}

export const FlipCountUp: React.FC<IProps> = (props: IProps) => {
  const { sx, panelSx, prefix, start, end } = props
  const { countUp, update } = useCountUp({
    end,
    start,
  })
  const digits = getDigitsFromNumber(countUp)

  useEffect(() => {
    update(end)
  }, [end])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      {prefix && (
        <FlipContainer
          sx={{
            marginRight: '12px',
            ...panelSx,
          }}
        >
          <StaticCard position="upper" digit={prefix} />
          <StaticCard position="lower" digit={prefix} />
        </FlipContainer>
      )}
      {digits.map(digit => (
        // eslint-disable-next-line react/jsx-key
        <FlipNumberPanel digit={digit} sx={panelSx} />
      ))}
    </Box>
  )
}
