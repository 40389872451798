import React from 'react'
import { Box, SxStyleProp } from 'rebass'
import { TextBlockWithLargeParagraph } from '..'
import { StandardPadding } from '../layout'
import { theme } from '../styles'
import { ITextBlockProps, TextBlock } from './text-block'

interface ITextHeroProps extends ITextBlockProps {
  withPadding?: boolean
  customTopPadding?: string[] | string | number[] | number
  customHorizontalPadding?: string[] | string | number[] | number
  hasBlueBackground?: boolean
  hasGreyBackground?: boolean
  hasBrownBackground?: boolean
  hasFullScreenWidth?: boolean
  customHeight?: string[] | string
  centered?: boolean
  leftAlignText?: boolean
  textHeroPaddingBottom?: string[] | string
  containerSx?: SxStyleProp
  withLargeParagraph?: boolean
}

export const TextHero = (props: ITextHeroProps) => {
  const {
    withPadding,
    customHorizontalPadding,
    hasBlueBackground,
    hasGreyBackground,
    hasBrownBackground,
    hasFullScreenWidth,
    customHeight,
    centered,
    leftAlignText,
    textHeroPaddingBottom,
    containerSx,
    withLargeParagraph,
  } = props

  const renderBody = () => (
    <Box
      sx={{
        paddingX: customHorizontalPadding || [0, 0, '10%'],
        width: hasFullScreenWidth ? '100vw' : theme.contentMaximumWidth,
        height: customHeight || '',
        backgroundColor: hasBlueBackground
          ? theme.colors.blue
          : hasBrownBackground
          ? theme.colors.gold100
          : hasGreyBackground
          ? theme.colors.lightGrey
          : '',
        color: hasBlueBackground || hasBrownBackground ? theme.colors.white : theme.colors.darkGrey,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: centered ? 'center' : leftAlignText ? 'flex-start' : 'center',
        paddingBottom: textHeroPaddingBottom || '0px',
        ...containerSx,
      }}
    >
      {withLargeParagraph ? <TextBlockWithLargeParagraph {...props} centered /> : <TextBlock {...props} centered />}
    </Box>
  )
  return withPadding ? <StandardPadding>{renderBody()}</StandardPadding> : renderBody()
}
