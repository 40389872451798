import React, { ReactElement } from 'react'
import { Box, SxStyleProp } from 'rebass'
import { Button, Text as N1Text } from '@northone/ui'

import { HomeMaxWidth } from '../../shared/components/home-max-width'
import { theme, IButtonProps, TextBlockList, Text } from '../../shared'
import { AnimatedATMPopup } from '../../components/front-page/animated-atm-popup'
import { CTAButton, IProps } from './v2/CtaButton/ctaButton'
// import { handleButtonClick } from '.'

interface IListItemProps {
  imageComponent?: ReactElement
  imageSrc?: string
  imageAlt?: string
  paragraph: string
}
interface IBoxOnBackgroundProps {
  title: string
  tagline: string
  paragraph?: string
  listItems: IListItemProps[]
  withBlueTheme?: boolean
  button?: IButtonProps
  ctaButton?: IProps
  backgroundImageUrl: string[]
  mobileBackgroundImageUrl?: string
  withATMPopup?: boolean
  noBorderRadius?: boolean
  containerSx?: SxStyleProp
}

export const BoxOnBackground = ({
  title,
  tagline,
  paragraph,
  listItems,
  button,
  ctaButton,
  backgroundImageUrl,
  mobileBackgroundImageUrl,
  withATMPopup,
  noBorderRadius,
  containerSx,
}: IBoxOnBackgroundProps) => (
  <>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        bg: theme.colors.gold10,
        paddingTop: [0, 20, 20],
        paddingBottom: [0, 0, 20],
        backgroundImage: [
          `url('${mobileBackgroundImageUrl}')`,
          `url('${mobileBackgroundImageUrl}')`,
          backgroundImageUrl.map(url => `url('${url}')`),
        ],
        backgroundPosition: 'center',
        backgroundSize: ['100% auto', '100% auto', 'auto 100%'],
        backgroundRepeat: 'no-repeat',
        height: ['752px', '752px', '100%', '100%', '100%'],
        ...containerSx,
      }}
    >
      <HomeMaxWidth
        sx={{
          flexDirection: ['column', 'column', 'row'],
          alignItems: ['center', 'center', undefined],
          justifyContent: ['space-between'],
          width: ['100%', '1145px'],
        }}
      >
        <Box
          sx={{
            bg: theme.colors.ui7,
            paddingY: [28, 28, 28, 80],
            marginY: [50, 50, 100],
            marginBottom: 100,
            paddingX: [17, 17, 28, 40],
            width: ['85%', '80%', '530px'],
            marginRight: [0, 0, 50],
            display: 'flex',
            flexDirection: 'column',
            alignItems: ['center', 'center', 'flex-start'],
            borderRadius: noBorderRadius ? null : 4,
          }}
        >
          <Text sx={{ marginBottom: theme.verticalElementSpacing, ...theme.fontstyles.eyeBrowText }} tag="h5">
            {tagline.toUpperCase()}
          </Text>
          <N1Text
            tag={['h3Serif', 'h3Serif', 'h3Serif', 'h2']}
            sx={{
              color: theme.colors.blackPrimary,
              textAlign: ['center', 'center', 'center', 'left', 'left'],
              fontFamily: theme.fonts.plantinProSemiBold,
              fontSize: ['24px', '36px'],
              lineHeight: ['28px', '40px'],
              paddingX: ['20px', '0px'],
            }}
          >
            {title}
          </N1Text>
          {paragraph && (
            <Text tag="p" sx={{ marginTop: 20 }}>
              {paragraph}
            </Text>
          )}
          {listItems.map(({ imageComponent, imageSrc = '', imageAlt, paragraph }, idx) =>
            imageComponent ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: ['column', 'column', 'row'],
                  ...(idx ? { marginTop: theme.verticalElementSpacing / 2 } : { marginTop: 20 }),
                }}
              >
                <Box sx={{ height: 64, width: 64, flexShrink: 0 }}>{imageComponent}</Box>
                <Text
                  sx={{
                    marginLeft: 15,
                    fontFamily: theme.fonts.grtskPetaLight,
                    textAlign: ['center', 'center', 'left'],
                    color: theme.colors.blackPrimary,
                  }}
                >
                  {paragraph}
                </Text>
              </Box>
            ) : (
              <TextBlockList
                customItemSpacing="10px"
                key={idx}
                imageSrc={imageSrc}
                imageAlt={imageAlt}
                paragraph={paragraph}
                textColor={theme.colors.ui1}
              />
            ),
          )}
          {button && (
            <Button
              sx={{
                marginTop: theme.verticalElementSpacing,
                ':hover': { backgroundColor: theme.colors.hoverStateBlackCTAs },
                ...button.sx,
              }}
              onClick={button.onClick}
            >
              <Text tag={'p'} sx={{ color: theme.colors.white || button.textColor }}>
                {button.text}
              </Text>
            </Button>
          )}
          {ctaButton && (
            <CTAButton
              text={ctaButton.text}
              type={ctaButton.type}
              onClick={ctaButton.onClick}
              buttonSx={{ ...ctaButton.buttonSx }}
            />
          )}
        </Box>
        <Box
          sx={{
            width: ['83%', '83%', '55%', '55%', '55%'],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            marginBottom: ['50px', '50px', '50px', '0px', '0px'],
          }}
        >
          {withATMPopup && <AnimatedATMPopup animationBottomOffset="50%" />}
        </Box>
      </HomeMaxWidth>
    </Box>
  </>
)
