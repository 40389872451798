import React from 'react'
import Link from 'next/link'

interface IPrimaryLinkProps {
  children: React.ReactNode
  href: string
}

export const PrimaryLink = ({ href, children }: IPrimaryLinkProps): JSX.Element => (
  <Link href={href}>
    <a
      className="b"
      style={{
        color: '#009bff',
      }}
    >
      {children}
    </a>
  </Link>
)
