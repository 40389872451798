import React from 'react'
import { Button as RButton, Link, SxStyleProp } from 'rebass'
import { theme } from '../styles/theme'
import { isExternalURL } from '../../helper'

export type TButtonType =
  | 'blue'
  | 'orange'
  | 'white'
  | 'grey'
  | 'greyBackground'
  | 'disabled'
  | 'darkbrown'
  | 'brown'
  | 'gold'
interface IProps {
  text: string
  url?: string
  type?: TButtonType
  style?: React.CSSProperties
  customButtonWidth?: (string | number)[] | ''
  buttonExtraPaddingBottom?: string[] | string
  onClick?: () => void
  textStyle?: SxStyleProp
}

const buttonColors = {
  blue: {
    color: theme.colors.white,
    backgroundColor: theme.colors.blue,
    fontWeight: 'bold',
  },
  orange: {
    color: theme.colors.white,
    backgroundColor: theme.colors.orange,
    fontWeight: 'bold',
  },
  white: {
    color: theme.colors.gold100,
    backgroundColor: theme.colors.white,
    fontWeight: 'bold',
  },
  grey: {
    color: theme.colors.mediumGrey,
    backgroundColor: theme.colors.white,
    fontWeight: 'bold',
  },
  darkbrown: {
    color: theme.colors.brown80,
    backgroundColor: theme.colors.white,
  },
  brown: {
    color: theme.colors.ui2,
    backgroundColor: 'transparent',
    fontWeight: 'bold',
  },
  greyBackground: {
    color: theme.colors.mediumGrey,
    backgroundColor: theme.colors.lightGrey,
    fontWeight: 'bold',
  },
  gold: {
    color: theme.colors.white,
    backgroundColor: theme.colors.gold100,
    fontWeight: 'bold',
  },
  disabled: {
    color: theme.colors.white,
    backgroundColor: theme.colors.disabledGrey,
    fontWeight: 'bold',
    ':hover': {
      cursor: 'default',
    },
  },
}

const getButtonColors = (buttonType: TButtonType) => {
  return buttonColors[buttonType]
}

export const Button = ({
  text = '',
  url = '',
  type = 'gold',
  style,
  customButtonWidth = '',
  buttonExtraPaddingBottom,
  onClick,
  textStyle,
}: IProps) => {
  return (
    <Link
      sx={{
        marginTop: theme.verticalElementSpacing,
        ...(style as SxStyleProp),
      }}
      href={url === '' ? undefined : url}
      onClick={onClick}
      target={isExternalURL(url) ? '_blank' : ''}
    >
      <RButton
        sx={{
          outline: 'none',
          borderRadius: 5,
          fontSize: 'button',
          height: theme.buttonHeight,
          width: customButtonWidth ? customButtonWidth : theme.buttonWidth,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          marginBottom: buttonExtraPaddingBottom || '',
          ...getButtonColors(type),
          ...(textStyle as SxStyleProp),
        }}
        disabled={type === 'disabled'}
      >
        {text}
      </RButton>
    </Link>
  )
}
