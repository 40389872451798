export const WEB_BANKING_URL = 'https://banking.northone.com/'
export const ONBOARDING_URL = 'https://join.northone.com/'

export enum LegalAgreements {
  ACCOUNT_AGREEMENT = 'ACCOUNT_AGREEMENT',
  ESIGN_AGREEMENT = 'ESIGN_AGREEMENT',
}

export const getLegalUrl = (agreement: LegalAgreements): string => {
  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://northone-agreements-prod.s3.amazonaws.com/pdf'
      : 'https://northone-agreements-dev.s3.us-east-2.amazonaws.com/pdf'
  const url = `${baseUrl}/${agreement}.pdf`

  return url
}
