/**
 * Shared Brand Styles as per Design System
 * Colors are named by opacity where 100 is fully opaque, 80 is 80% opaque and so on
 */

interface IUIColours {
  [color: string]: string
}
interface ITextStyles {
  [tag: string]: React.CSSProperties
}

const colors: IUIColours = {
  brown100: '#41351F',
  brown80: '#675D4C',
  brown60: '#8D8679',
  brown30: '#C6C3BC',
  brown10: '#ECEBE9',
  blue100: '#0D4E7D',
  blue80: '#3D7297',
  blue60: '#6E95B1',
  blue30: '#B7CAD9',
  blue10: '#E7EEF3',
  gold100: '#938666',
  gold80: '#A99E85',
  gold60: '#BEB6A3',
  gold30: '#DFDBD2',
  gold10: '#F9F8F5',
  green100: '#69999F',
  green80: '#78A4A9',
  green60: '#A5C2C5',
  green30: '#D2E1E3',
  green10: '#F0F5F6',
  red100: '#720909',
  red80: '#8E3A3A',
  red60: '#AA6B6B',
  red30: '#D5B6B6',
  red10: '#F1E7E7',
  purple100: '#3B3649',
  purple80: '#625E6D',
  purple60: '#898692',
  purple30: '#C5C3C9',
  purple10: '#ECEBED',
  ui1: '#272F36',
  ui2: '#858D92',
  ui3: '#AAB1BD',
  ui4: '#D9DDE4',
  ui5: '#F0F3F6',
  ui6: '#F7F7F7',
  ui7: '#FFF',
  error100: '#E41B24',
  error10: '#FEF2F2',
  borderGrey: '#CFD3DA',
}

const textStyles: ITextStyles = {
  h1: {
    fontSize: '48px',
    lineHeight: '50px',
    letterSpacing: '-0.16px',
    fontWeight: 300,
    fontFamily: 'Plantin-MT-Pro-Semi-Bold',
    textTransform: 'none',
  },
  h2: {
    fontSize: '36px',
    lineHeight: '40px',
    fontWeight: 300,
    fontFamily: 'Plantin-MT-Pro-Semi-Bold',
    letterSpacing: '-0.16px',
    textTransform: 'none',
  },
  h3: {
    fontSize: '20x',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'Plantin-MT-Pro-Semi-Bold',
    letterSpacing: '-0.16px',
    textTransform: 'none',
  },
  h3Serif: {
    fontSize: '20x',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'Plantin-MT-Pro-Semi-Bold',
    letterSpacing: '-0.16px',
    textTransform: 'none',
  },
  h4: {
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.16px',
    fontWeight: 500,
    fontFamily: 'Plantin-MT-Pro-Semi-Bold',
    textTransform: 'none',
  },
  h5: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    fontWeight: 500,
    fontFamily: 'Graphik',
    textTransform: 'uppercase',
  },
  'body-x-large': {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 'normal',
    fontFamily: 'Graphik',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  'body-large': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 'normal',
    fontFamily: 'Graphik',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  body: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'normal' as const,
    fontFamily: 'Graphik',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  'body-small': {
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 'normal',
    fontFamily: 'Graphik',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  'body-large-bold': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    fontFamily: 'Graphik',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  'body-bold': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    fontFamily: 'Graphik',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  'body-small-bold': {
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 500,
    fontFamily: 'Graphik',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  label: {
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 'normal',
    letterSpacing: '0.2px',
    fontFamily: 'Graphik',
    textTransform: 'none',
  },
  'label-bold': {
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    fontFamily: 'Graphik',
    textTransform: 'none',
  },
  'amount-input': {
    fontSize: '44px',
    lineHeight: '48px',
    fontWeight: 300,
    letterSpacing: '0.5px',
    fontFamily: 'Graphik',
    textTransform: 'none',
  },
  'body-x-small': {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'normal',
    fontFamily: 'Graphik',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
}

export const theme = {
  colors,
  textStyles,
  heights: {
    listItems: {
      small: 60,
      medium: 63,
      large: 68,
    },
  },
}
