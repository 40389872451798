import React, { useState, createRef, useEffect } from 'react'
import LazyLoad from 'react-lazyload'
import { Image, ImageProps } from 'rebass'
import '../styles/components-styles.css'

export const FadeInImage = (props: ImageProps) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const ref = createRef<HTMLImageElement>()
  useEffect(() => {
    if (ref && ref.current && ref.current.complete) {
      setHasLoaded(true)
    }
  }, [])

  return (
    <LazyLoad>
      <Image
        ref={ref}
        onLoad={() => {
          setHasLoaded(true)
        }}
        {...props}
        height={'auto'}
        width={'auto'}
        sx={{ opacity: hasLoaded ? 1 : 0, transition: 'opacity 0.3s', ...props.sx }}
      />
    </LazyLoad>
  )
}
